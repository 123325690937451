export enum UsersActionTypes {
  API_RESPONSE_SUCCESS = "@@users/API_RESPONSE_SUCCESS",
  API_RESPONSE_ERROR = "@@users/API_RESPONSE_ERROR",

  //Users Getters
  GET_USERS = "@@users/GET_USERS",
  GET_USER_BY_ID = "@@users/GET_USER_BY_ID",

  //User actions
  SET_USER = "@@users/SET_USER",
  SET_EDIT = "@@users/SET_EDIT",
  BLOCK_USER = "@@users/BLOCK_USER_BY_ID",
  UPDATE_USER = "@@users/UPDATE_USER",
  ACTIVATE_USER = "@@users/ACTIVATE_USER_BY_ID",
  DELETE_USER = "@@users/DELETE_USER_BY_ID",
}
