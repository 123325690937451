// apicore

// constants
import {DigitalOceanActions} from "./constants";
import {getSessionInfo} from "../../../helpers/integrations";

const INIT_STATE = getSessionInfo();

interface DigitalOceanActionType {
    type: | DigitalOceanActions.API_RESPONSE_SUCCESS | DigitalOceanActions.API_RESPONSE_ERROR | DigitalOceanActions.CONNECT | DigitalOceanActions.REBUILD | DigitalOceanActions.GET_DATA;
    payload: {
        actionType: string; data: any; error: string;
    };
}

interface State {
    ip?: string;
    reputation?: number;
    token?: string;
}

const DigitalOcean = (state: State = INIT_STATE, action: DigitalOceanActionType): any => {
    switch (action.type) {
        case DigitalOceanActions.API_RESPONSE_SUCCESS:
            switch (action.payload.actionType) {
                //Actions
                case DigitalOceanActions.CONNECT: {
                    return {
                        ...state, token: action.payload.data.token, loading: {
                            token: false
                        }, messages: {
                            success: {
                                ...((state as any)?.messages?.success ?? {}), token: action.payload.data.message
                            }, error: {
                                ...((state as any)?.messages?.error ?? {}), token: ''
                            }
                        }
                    };
                }
                case DigitalOceanActions.REBUILD: {
                    return {
                        ...state, loading: {
                            rebuild: false, reputation: true
                        }
                    };
                }
                case DigitalOceanActions.GET_DATA: {
                    const response = action.payload.data;
                    return {
                        ...state,
                        ip: response.ip,
                        reputation: response.reputation,
                        total: response['total'],
                        totalSuccess: response['total_success'],
                        totalBlocked: response['total_blocked'],
                        token: response.token,
                        loading: {
                            reputation: false, token: false, rebuild: false,
                        }
                    };
                }
                default:
                    return {
                        ...state, loading: {
                            token: false, rebuild: false, reputation: false,
                        }
                    };
            }

        case DigitalOceanActions.API_RESPONSE_ERROR:
            switch (action.payload.actionType) {
                case DigitalOceanActions.CONNECT: {
                    return {
                        ...state,
                        messages: {
                            success: {
                                ...((state as any)?.messages?.success ?? {}), token: ''
                            }, error: {
                                ...((state as any)?.messages?.error ?? {}), token: action.payload.error
                            }
                        }
                    };
                }
                case DigitalOceanActions.REBUILD: {
                    return {
                        ...state,
                        messages: {
                            success: {
                                ...((state as any)?.messages?.success ?? {}), token: ''
                            }, error: {
                                ...((state as any)?.messages?.error ?? {}), token: action.payload.error
                            }
                        }
                    };
                }
                default:
                    return {...state, loading: {}};
            }

        case DigitalOceanActions.CONNECT:
            return {...state, loading: {token: true}};
        case DigitalOceanActions.REBUILD:
            return {...state, loading: {rebuild: true}};
        case DigitalOceanActions.GET_DATA:
            return {...state, loading: {reputation: true, token: true, rebuild: false}};
        default:
            return {...state, loading: {}};
    }
};

export default DigitalOcean;
