import { all, fork, put, takeEvery, call } from "redux-saga/effects";
import { SagaIterator } from "@redux-saga/core";

// helpers
import {
    emailSingle as emailSingleApi,
    emailBulk as emailBulkApi,
    validateSingle as validateSingleApi,
    validateBulk as validateBulkApi,
    permutate as permutateApi,
} from "../../../helpers/integrations";

// actions
import { usersApiResponseSuccess, usersApiResponseError } from "./actions";

// constants
import { EmailActions } from "./constants";
import {getAuthorization, setAuthorization} from "../../../helpers/api/apiCore";

interface RequestData {
    payload: {
        token: string;
    };
    type: string;
}

type UserRequestData = RequestData & {
    payload: {
        userData: {
            firstName: string;
            lastName: string;
            domain: string;
        }
        isForce: boolean;
    }
}
type EmailRequestData = RequestData & {
    payload: {
        email: string;
        isForce: boolean;
    }
}
type EmailsBulkRequestData = RequestData & {
    payload: {
        emails?: string;
        isForce: boolean;
        form?: {
            emails: string;
            level: string;
        }
    }
}

/**
 * Send single email.
 *
 * @param {*} payload - username and password
 */
function* emailSingle({
    payload: {token, userData, isForce},
}: UserRequestData): SagaIterator {
    try {
        if (!getAuthorization()) {
            setAuthorization(token);
        }
        const response = yield call(() => emailSingleApi({...userData, isForce}));

        yield put(usersApiResponseSuccess(EmailActions.EMAIL_SINGLE, response.data));
    } catch (error: any) {
        setAuthorization(null);
        yield put(usersApiResponseError(EmailActions.EMAIL_SINGLE, error));
    }
}


/**
 * Find bulk emails.
 *
 * @param {*} payload - username and password
 */
function* emailBulk({
    payload: {token, form, isForce},
}: EmailsBulkRequestData): SagaIterator {
    try {
        if (!getAuthorization()) {
            setAuthorization(token);
        }
        const response = yield call(() => emailBulkApi({...form ?? ({} as any), isForce}));

        yield put(usersApiResponseSuccess(EmailActions.EMAIL_BULK, response.data));
    } catch (error: any) {
        setAuthorization(null);
        yield put(usersApiResponseError(EmailActions.EMAIL_BULK, error));
    }
}

/**
 * Validate Single email.
 *
 * @param {*} payload - username and password
 */
function* validateSingle({
    payload: {token, email, isForce},
}: EmailRequestData): SagaIterator {
    try {
        if (!getAuthorization()) {
            setAuthorization(token);
        }
        const response = yield call(() => validateSingleApi({email, isForce}));

        yield put(usersApiResponseSuccess(EmailActions.VALIDATE_SINGLE, response.data));
    } catch (error: any) {
        setAuthorization(null);
        yield put(usersApiResponseError(EmailActions.VALIDATE_SINGLE, error));
    }
}

/**
 * Validate Bulk emails.
 *
 * @param {*} payload - username and password
 */
function* validateBulk({
   payload: {token, emails, isForce},
}: EmailsBulkRequestData): SagaIterator {
    try {
        if (!getAuthorization()) {
            setAuthorization(token);
        }
        const response = yield call(() => validateBulkApi({emails: emails ?? "", isForce}));

        yield put(usersApiResponseSuccess(EmailActions.VALIDATE_BULK, response.data));
    } catch (error: any) {
        setAuthorization(null);
        yield put(usersApiResponseError(EmailActions.VALIDATE_BULK, error));
    }
}

/**
 * Validate Bulk emails.
 *
 * @param {*} payload - username and password
 */
function* permutate({
    payload: {token, userData},
}: UserRequestData): SagaIterator {
    try {
        if (!getAuthorization()) {
            setAuthorization(token);
        }
        const response = yield call(() => permutateApi(userData));

        yield put(usersApiResponseSuccess(EmailActions.PERMUTATE, response.data));
    } catch (error: any) {
        setAuthorization(null);
        yield put(usersApiResponseError(EmailActions.PERMUTATE, error));
    }
}

export function* watchSingle() {
    yield takeEvery(EmailActions.EMAIL_SINGLE, emailSingle);
}
export function* watchBulk() {
    yield takeEvery(EmailActions.EMAIL_BULK, emailBulk);
}
export function* watchValidateSingle() {
    yield takeEvery(EmailActions.VALIDATE_SINGLE, validateSingle);
}
export function* watchValidateBulk() {
    yield takeEvery(EmailActions.VALIDATE_BULK, validateBulk);
}
export function* watchPermutate() {
    yield takeEvery(EmailActions.PERMUTATE, permutate);
}

function* emailSaga() {
    yield all([
        fork(watchSingle),
        fork(watchBulk),
        fork(watchValidateSingle),
        fork(watchValidateBulk),
        fork(watchPermutate),
    ]);
}

export default emailSaga;
