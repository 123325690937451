// constants
import { EmailActions } from "./constants";

export interface DigitalOceanActionType {
  type:
    | EmailActions.API_RESPONSE_SUCCESS
    | EmailActions.API_RESPONSE_ERROR
    | EmailActions.EMAIL_BULK
    | EmailActions.VALIDATE_SINGLE
    | EmailActions.VALIDATE_BULK
    | EmailActions.PERMUTATE
    | EmailActions.EMAIL_SINGLE;
  payload: {} | string;
}

// common success
export const usersApiResponseSuccess = (
  actionType: string,
  data: any
): DigitalOceanActionType => ({
  type: EmailActions.API_RESPONSE_SUCCESS,
  payload: {actionType, data}
});
// common error
export const usersApiResponseError = (
  actionType: string,
  error: string
): DigitalOceanActionType => ({
  type: EmailActions.API_RESPONSE_ERROR,
  payload: { actionType, error },
});

//Actions
export const emailSingle = (
    token: string,
    userData: {
        firstName: string;
        lastName: string;
        domain: string;
    },
    isForce: boolean = false
): DigitalOceanActionType => ({
  type: EmailActions.EMAIL_SINGLE,
  payload: { token, userData, isForce },
});

export const emailBulk = (
    token: string,
    form: {
        emails: string;
        level: string;
    },
    isForce: boolean = false
): DigitalOceanActionType => ({
  type: EmailActions.EMAIL_BULK,
  payload: { token, form, isForce },
});


export const validateSingle = (
    token: string,
    email: string,
    isForce: boolean = false
): DigitalOceanActionType => ({
    type: EmailActions.VALIDATE_SINGLE,
    payload: { token, email, isForce },
});

export const validateBulk = (
    token: string,
    emails: string,
    isForce: boolean = false
): DigitalOceanActionType => ({
    type: EmailActions.VALIDATE_BULK,
    payload: { token, emails, isForce },
});

export const permutate = (
    token: string,
    userData: {
        firstName: string;
        lastName: string;
        domain: string;
    }
): DigitalOceanActionType => ({
    type: EmailActions.PERMUTATE,
    payload: { token, userData },
});
