// constants
import {ActionTypes, WarmupActionTypes} from "./constants";
import {SettingsData} from "../../pages/profstep/warmup/settings";

export interface AuthActionType {
    type: ActionTypes;
    payload: {} | string;
}

export interface EmailData {
    firstName: string;
    lastName: string;
    email: string;
    type: string;
    strategy: string;
    provider: string;
    profileType: string;
    status?: string;
}

// common success
export const warmupApiResponseSuccess = (
    actionType: string,
    data: EmailData | {}
): AuthActionType => ({
    type: WarmupActionTypes.API_RESPONSE_SUCCESS,
    payload: { actionType, data },
});
// common error
export const warmupApiResponseError = (
    actionType: string,
    error: string
): AuthActionType => ({
    type: WarmupActionTypes.API_RESPONSE_ERROR,
    payload: { actionType, error },
});

export const getAll = (token: string): AuthActionType => ({
    type: WarmupActionTypes.GET_ALL,
    payload: {token},
});

export const add = (
    token: string,
    emailData: EmailData
): AuthActionType => ({
    type: WarmupActionTypes.SAVE,
    payload: {token, emailData},
});

export const deleteEmail = (
    token: string,
    id: number
): AuthActionType => ({
    type: WarmupActionTypes.DELETE,
    payload: {token, id},
});

export const save = (
    token: string,
    emailData: EmailData
): AuthActionType => ({
    type: WarmupActionTypes.SAVE,
    payload: {token, emailData},
});

export const test = (
    token: string,
    email: string,
    id: number
): AuthActionType => ({
    type: WarmupActionTypes.TEST,
    payload: {token, email, id},
});

export const run = (
    token: string,
    id: number
): AuthActionType => ({
    type: WarmupActionTypes.RUN,
    payload: {token, id},
});

export const pause = (
    token: string,
    id: number
): AuthActionType => ({
    type: WarmupActionTypes.PAUSE,
    payload: {token, id},
});

export const clean = (
    token: string,
    id: number
): AuthActionType => ({
    type: WarmupActionTypes.CLEAN,
    payload: {token, id},
});
export const removeFromSpam = (
    token: string
): AuthActionType => ({
    type: WarmupActionTypes.REMOVE_FROM_SPAM,
    payload: {token},
});

export const getInfo = (
    token: string,
    id: string
): AuthActionType => ({
    type: WarmupActionTypes.GET_INFO,
    payload: { token, id },
});

export const saveSettings = (
    token: string,
    settings: SettingsData
): AuthActionType => ({
    type: WarmupActionTypes.SAVE_SETTINGS,
    payload: {token, settings},
});

export const getSettings = (
    token: string
): AuthActionType => ({
    type: WarmupActionTypes.GET_SETTINGS,
    payload: { token },
});
