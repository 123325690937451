import { APICore } from "../api/apiCore";

const api = new APICore();

// Actions
function emailSingle(userData: {
    firstName: string;
    lastName: string;
    domain: string;
    isForce: boolean;
}) {
    const baseUrl = `/api/emails/single`;
    return api.create(`${baseUrl}`, userData);
}

function emailBulk(request: {
    emails: string;
    level: string;
    isForce: boolean;
}) {
    const baseUrl = `/api/emails/bulk`;
    return api.create(`${baseUrl}`, request);
}

function validateSingle(request: {
    email: string;
    isForce: boolean;
}) {
    const baseUrl = `/api/emails/validate_single`;
    return api.create(`${baseUrl}`, request);
}

function validateBulk(userData: {
    emails: string;
    isForce: boolean;
}) {
    const baseUrl = `/api/emails/validate_bulk`;
    return api.create(`${baseUrl}`, userData);
}

function permutate(userData: {
    firstName: string;
    lastName: string;
    domain: string;
}) {
    const baseUrl = `/api/emails/permutate`;
    return api.create(`${baseUrl}`, userData);
}

export { emailSingle, emailBulk, validateSingle, validateBulk, permutate };
