// apicore
import { APICore } from "../../helpers/api/apiCore";

// constants
import {UsersActionTypes} from "./constants";
import {ContactsItems} from "../../pages/apps/Contacts/List/data";
import {FormatDateOptions} from "@fullcalendar/react";

const INIT_STATE = {
  users: []
};

interface UserData {
  id: number;
  username: string;
  password: string;
  firstName: string;
  lastName: string;
  role: string;
  token: string;
  created_at: string;
  updated_at: string;
}

interface UserActionType {
  type:
    | UsersActionTypes.API_RESPONSE_SUCCESS
    | UsersActionTypes.API_RESPONSE_ERROR
    | UsersActionTypes.GET_USERS
    | UsersActionTypes.GET_USER_BY_ID
    | UsersActionTypes.SET_USER
    | UsersActionTypes.SET_EDIT
    | UsersActionTypes.BLOCK_USER
    | UsersActionTypes.UPDATE_USER
    | UsersActionTypes.ACTIVATE_USER
    | UsersActionTypes.DELETE_USER;
  payload: {
    actionType?: string;
    data?: UserData[] | UserData | {};
    error?: string;
  };
}

interface State {
  users: ContactsItems[];
  loading?: boolean;
  value?: boolean;
}

export const DATE_FORMAT: FormatDateOptions = {
  year: "numeric",
  month: "short",
  day: "numeric",
};

const Users = (state: State = INIT_STATE, action: UserActionType): any => {
  switch (action.type) {
    case UsersActionTypes.API_RESPONSE_SUCCESS:
      switch (action.payload.actionType) {
        //Getters
        case UsersActionTypes.GET_USERS: {
          const usersData = (action.payload.data as UserData[]).map(user => ({
            ...user,
            created_at: new Date(user["created_at"]).toLocaleDateString("en-US", DATE_FORMAT)
          }));

          return {
            ...state,
            users: usersData,
            loading: false
          };
        }
        case UsersActionTypes.GET_USER_BY_ID: {
          return {
            ...state,
            loading: false
          };
        }

        //Actions
        case UsersActionTypes.SET_USER: {
          return {
            ...state,
            currentUser: action.payload.data,
            loading: false
          };
        }
        case UsersActionTypes.SET_EDIT: {
          return {
            ...state,
            isEdit: action.payload.data,
            loading: false
          };
        }
        case UsersActionTypes.BLOCK_USER: {
          return {
            ...state,
            user: null,
            loading: false
          };
        }
        case UsersActionTypes.DELETE_USER: {
          const response = action.payload.data as any;

          return {
            ...state,
            users: state.users.filter(user => user.id !== response.id),
            user: null,
            loading: false,
            deleteMessage: response.response.message
          };
        }
        case UsersActionTypes.UPDATE_USER: {
          const data = action.payload.data as any;

          const userData = {
            ...data.userData,
            userpic: data.response.userpic
          };
          return {
            ...state,
            currentUser: userData,
            users: state.users.map(user => user.id == userData.id ? userData : user),
            userMessage: data.response.message,
            isEdit: false,
            userLoading: false
          };
        }
        case UsersActionTypes.ACTIVATE_USER: {
          //debugger;
          return {
            ...state,
            loading: false
          };
        }
        default:
          return { ...state, loading: false };
      }

    case UsersActionTypes.API_RESPONSE_ERROR:
      switch (action.payload.actionType) {
        case UsersActionTypes.GET_USERS:
        case UsersActionTypes.SET_USER:
          return {...state, currentUser: {}}
        case UsersActionTypes.GET_USER_BY_ID:
        case UsersActionTypes.UPDATE_USER:
          return { ...state, userLoading: false };
        case UsersActionTypes.DELETE_USER:
        default:
          return { ...state, loading: false };
      }

    case UsersActionTypes.UPDATE_USER:
      return { ...state, userLoading: true };
    case UsersActionTypes.GET_USERS:
    case UsersActionTypes.GET_USER_BY_ID:
      return { ...state, loading: true };
    default:
      return { ...state, loading: false };
  }
};

export default Users;
