// constants
import { DigitalOceanActions } from "./constants";

export interface DigitalOceanActionType {
    type:
        | DigitalOceanActions.API_RESPONSE_SUCCESS
        | DigitalOceanActions.API_RESPONSE_ERROR
        | DigitalOceanActions.CONNECT
        | DigitalOceanActions.REBUILD
        | DigitalOceanActions.GET_DATA;
    payload: {} | string;
}

// common success
export const usersApiResponseSuccess = (
    actionType: string,
    data: any
): DigitalOceanActionType => ({
    type: DigitalOceanActions.API_RESPONSE_SUCCESS,
    payload: {actionType, data}
});
// common error
export const usersApiResponseError = (
    actionType: string,
    error: string
): DigitalOceanActionType => ({
    type: DigitalOceanActions.API_RESPONSE_ERROR,
    payload: { actionType, error },
});

//Actions
export const connect = (
    token: string,
    key: {
        api: string;
        verify: string;
    }
): DigitalOceanActionType => ({
    type: DigitalOceanActions.CONNECT,
    payload: { token, key },
});
export const rebuild = (
    token: string
): DigitalOceanActionType => ({
    type: DigitalOceanActions.REBUILD,
    payload: { token },
});
export const getData = (
    token: string
): DigitalOceanActionType => ({
    type: DigitalOceanActions.GET_DATA,
    payload: { token },
});
