// apicore
import { APICore } from "../../helpers/api/apiCore";

// constants
import {ActionTypes, WarmupActionTypes} from "./constants";
import {SettingsData} from "../../pages/profstep/warmup/settings";
import {stat} from "fs";

const api = new APICore();

const INIT_STATE = {
    loading: {},
    messages: {
        success: {},
        error: {},
    },
    settings: {}
};

type ResponseMessages = {
    [key in "success"|"error"]: {};
}

interface ResultData {
    messages: ResponseMessages;
    result: any;
}

interface AuthActionType {
    type: ActionTypes;
    payload: {
        actionType?: string;
        data: ResultData;
        error?: string;
    };
}

interface State {
    stats?: any;
    users?: Array<any>;
    loading: {},
    messages: ResponseMessages,
    settings: {
        [key in keyof SettingsData]?: SettingsData[key]
    }
}

const Warmup = (state: State = INIT_STATE, action: AuthActionType): any => {
    switch (action.type) {
        case WarmupActionTypes.API_RESPONSE_SUCCESS:
            switch (action.payload.actionType) {
                case WarmupActionTypes.GET_ALL: {
                    return {
                        ...state,
                        users: action.payload.data.result,
                        messages: {
                            ...state.messages,
                            ...action.payload.data.messages
                        },
                        loading: {
                            ...state.loading,
                            users: false
                        },
                    };
                }
                case WarmupActionTypes.DELETE: {
                    return {
                        ...state,
                        users: state.users?.filter(user => user.id !== +action.payload.data.result),
                        messages: {
                            ...state.messages,
                            ...action.payload.data.messages
                        },
                        loading: {
                            ...state.loading,
                            users: false,
                            delete: false
                        },
                    };
                }
                case WarmupActionTypes.CLEAN: {
                    return {
                        ...state,
                        stats: {
                            ...state.stats,
                            strategy: {}
                        },
                        messages: {
                            ...state.messages,
                            ...action.payload.data.messages
                        },
                        loading: {
                            ...state.loading,
                            clean: false,
                        },
                    };
                }
                case WarmupActionTypes.TEST: {
                    const messages = state.messages;

                    if (action.payload.data) {
                        messages.success = {
                            ...messages.success,
                            test: "Test email sent successfully. Please, check your mail box"
                        }
                    } else {
                        messages.error = {
                            ...messages.error,
                            test: "Failed to send test email. Please, try again later"
                        }
                    }

                    return {
                        ...state,
                        stats: {
                            ...state.stats,
                            strategy: {}
                        },
                        messages: {
                            ...state.messages,
                            ...messages
                        },
                        loading: {
                            ...state.loading,
                            clean: false,
                        },
                    };
                }
                case WarmupActionTypes.GET_INFO: {
                    delete state.stats;
                    return {
                        ...state,
                        stats: action.payload.data.result,
                        messages: {
                            ...state.messages,
                            ...action.payload.data.messages
                        },
                        loading: {
                            ...state.loading,
                            stats: false
                        },
                    };
                }
                case WarmupActionTypes.SAVE: {
                    return {
                        ...state,
                        stats: {
                            ...state.stats,
                            ...action.payload.data.result
                        },
                        messages: {
                            ...state.messages,
                            success: {...state.messages.success, ...action.payload.data.messages}
                        },
                        loading: {
                            ...state.loading,
                            save: false
                        },
                    };
                }
                case WarmupActionTypes.RUN: {
                    return {
                        ...state,
                        stats: {
                            ...state.stats,
                            info: {
                                ...state.stats?.info,
                                status: "running"
                            }
                        },
                        messages: {
                            ...state.messages,
                            success: {...state.messages.success, ...action.payload.data.messages}
                        },
                        loading: {
                            ...state.loading,
                            run: false
                        },
                    };
                }
                case WarmupActionTypes.PAUSE: {
                    return {
                        ...state,
                        error: action.payload.error,
                        stats: {
                            ...state.stats,
                            info: {
                                ...state.stats?.info,
                                status: "paused"
                            }
                        },
                        messages: {
                            ...state.messages,
                            error: {...state.messages.error, ...action.payload.data.messages}
                        },
                        loading: {
                            ...state.loading,
                            pause: false
                        },
                    };
                }
                case WarmupActionTypes.GET_SETTINGS: {
                    return {
                        ...state,
                        settings: {
                            ...(state.settings ?? {}),
                            ...action.payload.data.result
                        },
                        messages: {
                            ...state.messages,
                            success: {...state.messages.success, ...action.payload.data.messages}
                        },
                        loading: {
                            ...state.loading,
                            settings: false
                        },
                    };
                }
                case WarmupActionTypes.SAVE_SETTINGS: {
                    return {
                        ...state,
                        settings: {
                            ...state.settings,
                            ...action.payload.data.result
                        },
                        messages: {
                            ...state.messages,
                            success: {...state.messages.success, ...action.payload.data.messages}
                        },
                        loading: {
                            ...state.loading,
                            settings: false
                        },
                    };
                }
                case WarmupActionTypes.REMOVE_FROM_SPAM: {
                    return {
                        ...state,
                        messages: {
                            ...state.messages,
                            success: {...state.messages.success, ...action.payload.data.messages}
                        },
                        loading: {
                            ...state.loading,
                            removeFromSpam: false
                        },
                    };
                }
                default:
                    return { ...state };
            }

        case WarmupActionTypes.API_RESPONSE_ERROR:
            switch (action.payload.actionType) {
                case WarmupActionTypes.GET_ALL: {
                    return {
                        ...state,
                        error: action.payload.error,
                        loading: {
                            ...state.loading,
                            users: false
                        },
                    };
                }
                case WarmupActionTypes.GET_INFO: {
                    return {
                        ...state,
                        error: action.payload.error,
                        stats: {},
                        loading: {
                            ...state.loading,
                            stats: false
                        },
                    };
                }
                case WarmupActionTypes.GET_SETTINGS:
                case WarmupActionTypes.SAVE_SETTINGS: {
                    return {
                        ...state,
                        error: action.payload.error,
                        messages: {
                            ...state.messages,
                            error: {...state.messages.error, ...action.payload.data.messages}
                        },
                        loading: {
                            ...state.loading,
                            settings: false
                        },
                    };
                }
                case WarmupActionTypes.REMOVE_FROM_SPAM: {
                    return {
                        ...state,
                        messages: {
                            ...state.messages,
                            success: {...state.messages.success, ...action.payload.data.messages}
                        },
                        loading: {
                            ...state.loading,
                            removeFromSpam: false
                        },
                    };
                }
                case WarmupActionTypes.SAVE: {
                    return {
                        ...state,
                        error: action.payload.error,
                        messages: {
                            ...state.messages,
                            error: {...state.messages.error, ...action.payload.data.messages}
                        },
                        loading: {
                            ...state.loading,
                            save: false
                        },
                    };
                }
                case WarmupActionTypes.RUN: {
                    return {
                        ...state,
                        error: action.payload.error,
                        stats: {
                            ...state.stats,
                            info: {
                                ...state.stats.info,
                                status: "paused"
                            }
                        },
                        messages: {
                            ...state.messages,
                            error: {...state.messages.error, ...action.payload.data.messages}
                        },
                        loading: {
                            ...state.loading,
                            run: false
                        },
                    };
                }
                case WarmupActionTypes.PAUSE: {
                    return {
                        ...state,
                        error: action.payload.error,
                        stats: {
                            ...state.stats,
                            info: {
                                ...state.stats.info,
                                status: "running"
                            }
                        },
                        messages: {
                            ...state.messages,
                            error: {...state.messages.error, ...action.payload.data.messages}
                        },
                        loading: {
                            ...state.loading,
                            pause: false
                        },
                    };
                }
                case WarmupActionTypes.CLEAN: {
                    return {
                        ...state,
                        messages: {
                            ...state.messages,
                            ...action.payload.data.messages
                        },
                        loading: {
                            ...state.loading,
                            clean: false,
                        },
                    };
                }
                case WarmupActionTypes.TEST: {
                    return {
                        ...state,
                        messages: {
                            ...state.messages,
                            error: {
                                ...state.messages.error,
                                test: "Failed to test email. Please, try again later"
                            }
                        },
                        loading: {
                            ...state.loading,
                            clean: false,
                        },
                    };
                }
                default:
                    return { ...state };
            }

        case WarmupActionTypes.GET_ALL:
            return { ...state, loading: {...state.loading, users: true} };
        case WarmupActionTypes.DELETE:
            return { ...state, loading: {...state.loading, users: true, delete: true} };
        case WarmupActionTypes.SAVE:
            return { ...state, loading: {...state.loading, save: true} };
        case WarmupActionTypes.RUN:
            return { ...state, loading: {...state.loading, run: true} };
        case WarmupActionTypes.PAUSE:
            return { ...state, loading: {...state.loading, pause: true} };
        case WarmupActionTypes.CLEAN:
            return { ...state, loading: {...state.loading, clean: true} };
        case WarmupActionTypes.GET_SETTINGS:
        case WarmupActionTypes.SAVE_SETTINGS:
            return { ...state, loading: {...state.loading, settings: true} };
        case WarmupActionTypes.REMOVE_FROM_SPAM:
            return { ...state, loading: {...state.loading, removeFromSpam: true} };
        case WarmupActionTypes.GET_INFO:
            return { ...state, loading: {...state.loading, stats: true} };
        default:
            return { ...state };
    }
};

export default Warmup;
