export enum WarmupActionTypes {
  API_RESPONSE_SUCCESS = "@@warmup/API_RESPONSE_SUCCESS",
  API_RESPONSE_ERROR = "@@warmup/API_RESPONSE_ERROR",

  GET_ALL = "@@warmup/GET_ALL",
  GET_INFO = "@@warmup/GET_INFO",
  SAVE = "@@warmup/SAVE",
  DELETE = "@@warmup/DELETE",

  RUN = "@@warmup/RUN",
  PAUSE = "@@warmup/PAUSE",
  CLEAN = "@@warmup/CLEAN",
  TEST = "@@warmup/TEST",

  GET_SETTINGS = "@@warmup/GET_SETTINGS",
  SAVE_SETTINGS = "@@warmup/SAVE_SETTINGS",
  REMOVE_FROM_SPAM = "@@warmup/REMOVE_FROM_SPAM",
}

export type ActionTypes = | WarmupActionTypes.API_RESPONSE_SUCCESS
    | WarmupActionTypes.API_RESPONSE_ERROR
    | WarmupActionTypes.GET_ALL
    | WarmupActionTypes.GET_INFO
    | WarmupActionTypes.SAVE
    | WarmupActionTypes.DELETE
    | WarmupActionTypes.RUN
    | WarmupActionTypes.PAUSE
    | WarmupActionTypes.CLEAN
    | WarmupActionTypes.TEST
    | WarmupActionTypes.GET_SETTINGS
    | WarmupActionTypes.SAVE_SETTINGS
    | WarmupActionTypes.REMOVE_FROM_SPAM;
