// constants
import {ActionTypes, CompaniesActionTypes} from "./constants";
import {GetAllFilters} from "../../helpers/companies/general";

export interface CompaniesActionType {
    type: ActionTypes;
    payload: {} | string;
}

export interface CompanyData {
    firstName: string;
    lastName: string;
    email: string;
    type: string;
    strategy: string;
    provider: string;
    profileType: string;
    status?: string;
}

export interface GroupedCompanyData {
    [key: string]: {[key:string]: any}
}

export type CustomAttributeMeta  = {
    id?: number,
    code: string,
    label: string,
    type: string
} & {[key: string]: any};

export type CustomAttributesValues = {
    companyId: number,
    data: {
        [key: string]: CustomAttributeMeta
    }
}

// common success
export const companiesApiResponseSuccess = (
    actionType: string,
    data: {}
): CompaniesActionType => ({
    type: CompaniesActionTypes.API_RESPONSE_SUCCESS,
    payload: { actionType, data },
});
// common error
export const companiesApiResponseError = (
    actionType: string,
    error: string
): CompaniesActionType => ({
    type: CompaniesActionTypes.API_RESPONSE_ERROR,
    payload: { actionType, error },
});

export const getCompanies = (token: string, settings: GetAllFilters = {}): CompaniesActionType => ({
    type: CompaniesActionTypes.GET_ALL,
    payload: {token, settings},
});

export const getCompanyLists = (token: string, settings: GetAllFilters = {}): CompaniesActionType => ({
    type: CompaniesActionTypes.GET_ALL_LISTS,
    payload: {token, settings},
});

export const getOptionsLists = (token: string): CompaniesActionType => ({
    type: CompaniesActionTypes.GET_OPTIONS_LIST,
    payload: {token},
});

export const getCompanyInfo = (
    token: string,
    id: string
): CompaniesActionType => ({
    type: CompaniesActionTypes.GET_INFO,
    payload: { token, id },
});
export const uploadCompanies = (
    token: string,
    data: object
): CompaniesActionType => ({
    type: CompaniesActionTypes.UPLOAD,
    payload: { token, data },
});
export const reindexCompanies = (
    token: string,
): CompaniesActionType => ({
    type: CompaniesActionTypes.REINDEX,
    payload: { token },
});

export const saveCompaniesCustomAttributes = (
    token: string,
    data: CustomAttributeMeta[]
): CompaniesActionType => ({
    type: CompaniesActionTypes.SAVE_CUSTOM_ATTRIBUTES,
    payload: { token, data },
});

export const saveCompaniesCustomAttributesValues = (
    token: string,
    data: CustomAttributesValues
): CompaniesActionType => ({
    type: CompaniesActionTypes.SAVE_CUSTOM_ATTRIBUTES_VALUES,
    payload: { token, data },
});

export const getCompaniesCustomAttributesMeta = (
    token: string
): CompaniesActionType => ({
    type: CompaniesActionTypes.GET_CUSTOM_ATTRIBUTES_META,
    payload: { token },
});

export const setCompaniesCustomAttributesMeta = (
    newVal: any[]
): CompaniesActionType => ({
    type: CompaniesActionTypes.SET_CUSTOM_ATTRIBUTES_META,
    payload: {data: newVal},
});
export const setSortBy = (
    data: string
): CompaniesActionType => ({
    type: CompaniesActionTypes.SET_SORT_BY,
    payload: { data },
});
export const setSortOrder = (
    data: string
): CompaniesActionType => ({
    type: CompaniesActionTypes.SET_SORT_ORDER,
    payload: { data },
});
export const setPageSize = (
    data: number
): CompaniesActionType => ({
    type: CompaniesActionTypes.SET_PAGE_SIZE,
    payload: { data },
});
export const getFiltersView = (
    token: string,
    customerId: number
): CompaniesActionType => ({
    type: CompaniesActionTypes.GET_VIEW,
    payload: { token, customerId },
});
export const setViewConfig = (
    code: string,
    newState: object
): CompaniesActionType => ({
    type: CompaniesActionTypes.SET_VIEW,
    payload: { code, newState },
});

export const saveFiltersView = (
    token: string,
    customerId: number,
    data: any
): CompaniesActionType => ({
    type: CompaniesActionTypes.SAVE_VIEW,
    payload: { token, customerId, data },
});
export const saveCompaniesList = (
    token: string,
    data: object
): CompaniesActionType => ({
    type: CompaniesActionTypes.SAVE_LIST,
    payload: { token, data },
});
export const addToCompaniesList = (
    token: string,
    data: object
): CompaniesActionType => ({
    type: CompaniesActionTypes.ADD_TO_LIST,
    payload: { token, data },
});

export const getGroupsLayoutView = (
    token: string
): CompaniesActionType => ({
    type: CompaniesActionTypes.GET_GROUPS_LAYOUT_VIEW,
    payload: { token },
});

export const saveGroupsLayoutView = (
    token: string,
    data: object
): CompaniesActionType => ({
    type: CompaniesActionTypes.SAVE_GROUPS_LAYOUT_VIEW,
    payload: {
        token,
        data
    },
});

export const getUploadProgress = (
    token: string
): CompaniesActionType => ({
    type: CompaniesActionTypes.GET_UPLOAD_PROGRESS,
    payload: { token },
});
