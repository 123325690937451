import { APICore } from "../api/apiCore";
import {EmailData} from "../../redux/warmup/actions";
import {SettingsData} from "../../pages/profstep/warmup/settings";

const api = new APICore();

type WarmupSettingsRequest = {
}

type SaveRequest = WarmupSettingsRequest & {
    settings: SettingsData;
}

// Actions
function getSettings() {
    const baseUrl = "/api/warmup/settings";
    return api.get(`${baseUrl}`, {});
}

function saveSettings(settings: SaveRequest) {
    const baseUrl = "/api/warmup/settings/save";
    return api.create(`${baseUrl}`, {...settings.settings});
}

function getSpamLetters() {
    const baseUrl = "/api/warmup/spam";
    return api.get(`${baseUrl}`, {});
}
function removeFromSpam() {
    const baseUrl = "/api/warmup/spam/remove";
    return api.get(`${baseUrl}`, {});
}

export { getSettings, saveSettings, getSpamLetters, removeFromSpam };
