import { APICore } from "../api/apiCore";

const api = new APICore();

type SendGridRequest = {
}

type ConnectRequest = SendGridRequest & {
    keys: {
        [key: string]: string
    }
}
type VerifyRequest = SendGridRequest & {
    email: string;
}
type BulkVerifyRequest = SendGridRequest & {
    emails: string;
}

// Actions
function verify({email}: VerifyRequest) {
    const baseUrl = "/api/sendgrid/verify";
    return api.create(`${baseUrl}`,{email});
}
function verifyBulk({emails}: BulkVerifyRequest) {
    const baseUrl = "/api/sendgrid/verify-bulk";
    return api.create(`${baseUrl}`,{emails});
}

function connectSg({keys}: ConnectRequest) {
    const baseUrl = "/api/sendgrid/connect";
    return api.create(`${baseUrl}`,{key: keys});
}

function sgStatus() {
    const baseUrl = "/api/sendgrid/status";
    return api.get(`${baseUrl}`, null);
}

export { verify, verifyBulk, connectSg, sgStatus }
