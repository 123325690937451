import { APICore } from "./apiCore";

const api = new APICore();

// Getters
function allUsers() {
    const baseUrl = "/api/getallusers";
    return api.get(`${baseUrl}`,{});
}
function userById(params: {id: string}) {
    const baseUrl = `/api/getuserbyid/${params.id}`;
    return api.get(`${baseUrl}`,{});
}
function updateUser(params: {userData: object, id: number}) {
    const baseUrl = `/api/updateuser/${params.id}`;
    return api.createWithFile(`${baseUrl}`, params.userData);
}
function deleteUser(params: {id: number}) {
    const baseUrl = `/api/deleteuser/${params.id}`;
    return api.delete(`${baseUrl}`);
}

// Actions


export { allUsers, userById, updateUser, deleteUser };
