import config from "../config";
import { useLocation } from "react-router-dom";

//helper for switching layout

// constants
import {LayoutTypes} from "../constants/layout";

const hoizontalLayoutPages = config.links;

const LayoutHelper = () => {
    let location = useLocation();

    if (
        !hoizontalLayoutPages.hasOwnProperty(location.pathname)
        && !Object.keys(hoizontalLayoutPages).some(
            key => {
                try {
                    return (new RegExp("^" + key + "$")).test(location.pathname);
                } catch (e) {
                    return false;
                }
            }
        )
    ) {
        return LayoutTypes.LAYOUT_TWO_COLUMN
    } else {
        return  LayoutTypes.LAYOUT_HORIZONTAL
    }
}

export default LayoutHelper;
