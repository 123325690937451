// constants
import { UsersActionTypes } from "./constants";

export interface UserActionType {
  type:
    | UsersActionTypes.API_RESPONSE_SUCCESS
    | UsersActionTypes.API_RESPONSE_ERROR
    | UsersActionTypes.GET_USERS
    | UsersActionTypes.GET_USER_BY_ID
    | UsersActionTypes.SET_USER
    | UsersActionTypes.SET_EDIT
    | UsersActionTypes.BLOCK_USER
    | UsersActionTypes.UPDATE_USER
    | UsersActionTypes.ACTIVATE_USER
    | UsersActionTypes.DELETE_USER;
  payload: {} | string;
}

interface UserData {
  id: number;
  username: string;
  password: string;
  firstName: string;
  lastName: string;
  role: string;
  token: string;
}

// common success
export const usersApiResponseSuccess = (
  actionType: string,
  data: UserData[] | {}
): UserActionType => ({
  type: UsersActionTypes.API_RESPONSE_SUCCESS,
  payload: {actionType, data}
});
// common error
export const usersApiResponseError = (
  actionType: string,
  error: string
): UserActionType => ({
  type: UsersActionTypes.API_RESPONSE_ERROR,
  payload: { actionType, error },
});

//Getters
export const getUsers = (token: string): UserActionType => ({
  type: UsersActionTypes.GET_USERS,
  payload: {token: token},
});
export const getUserById = (
    userId: number
): UserActionType => ({
  type: UsersActionTypes.GET_USER_BY_ID,
  payload: { id: userId },
});

//Actions
export const setUser = (
    userData: object
): UserActionType => ({
  type: UsersActionTypes.SET_USER,
  payload: { userData },
});
export const setEdit = (
    isEdit: boolean
): UserActionType => ({
  type: UsersActionTypes.SET_EDIT,
  payload: { isEdit },
});
export const blockUser = (
    userId: number
): UserActionType => ({
  type: UsersActionTypes.BLOCK_USER,
  payload: { id: userId },
});
export const updateUser = (
    userData: object,
    token: string
): UserActionType => ({
  type: UsersActionTypes.UPDATE_USER,
  payload: { userData, token },
});
export const activateUser = (
    userId: number
): UserActionType => ({
  type: UsersActionTypes.ACTIVATE_USER,
  payload: { id: userId },
});
export const deleteUser = (
    userId: number
): UserActionType => ({
  type: UsersActionTypes.DELETE_USER,
  payload: { id: userId },
});
