import { all, fork, put, takeEvery, call } from "redux-saga/effects";
import { SagaIterator } from "@redux-saga/core";

// helpers
import {
    verify as verifyApi,
    verifyBulk as verifyBulkApi,
    connectSg as connectApi,
    sgStatus as statusApi,
} from "../../../helpers/integrations";

// actions
import { sendgridApiResponseSuccess, sendgridApiResponseError } from "./actions";

// constants
import { SendGridActions } from "./constants";
import {getAuthorization, setAuthorization} from "../../../helpers/api/apiCore";

interface RequestData {
    payload: {
        token: string;
    };
    type: string;
}

type ConnectRequestData = RequestData & {
    payload: {
        keys: {
            [key: string]: string
        }
    }
}

type VerifyRequestData = RequestData & {
    payload: {
        email: string;
    }
}
type BulkVerifyRequestData = RequestData & {
    payload: {
        emails: string;
    }
}

/**
 * Connect to server with keys.
 *
 * @param {*} payload - email to verify
 */
function* connect({
    payload: {token, keys},
}: ConnectRequestData): SagaIterator {
    try {
        if (!getAuthorization()) {
            setAuthorization(token);
        }
        const response = yield call(() => connectApi({keys}));
        const data = response.data;

        yield put(sendgridApiResponseSuccess(SendGridActions.CONNECT, data));
    } catch (error: any) {
        setAuthorization(null);
        yield put(sendgridApiResponseError(SendGridActions.CONNECT, error));
    }
}

/**
 * Connect to server with keys.
 *
 * @param {*} payload - email to verify
 */
function* status({
    payload: {token},
}: RequestData): SagaIterator {
    try {
        if (!getAuthorization()) {
            setAuthorization(token);
        }
        const response = yield call(() => statusApi());
        const data = response.data;

        yield put(sendgridApiResponseSuccess(SendGridActions.CONNECT, data));
    } catch (error: any) {
        setAuthorization(null);
        yield put(sendgridApiResponseError(SendGridActions.CONNECT, error));
    }
}

/**
 * Verify if email is valid using sendgrid API.
 *
 * @param {*} payload - email to verify
 */
function* verify({
    payload: {token, email},
}: VerifyRequestData): SagaIterator {
    try {
        if (!getAuthorization()) {
            setAuthorization(token);
        }
        const response = yield call(() => verifyApi({email}));
        const data = response.data;

        yield put(sendgridApiResponseSuccess(SendGridActions.VERIFY, data));
    } catch (error: any) {
        setAuthorization(null);
        yield put(sendgridApiResponseError(SendGridActions.VERIFY, error));
    }
}

/**
 * Verify bulk emails using sendgrid API.
 *
 * @param {*} payload - emails to verify
 */
function* verifyBulk({
    payload: {token, emails},
}: BulkVerifyRequestData): SagaIterator {
    try {
        if (!getAuthorization()) {
            setAuthorization(token);
        }
        const response = yield call(() => verifyBulkApi({emails}));
        const data = response.data;

        debugger;
        yield put(sendgridApiResponseSuccess(SendGridActions.VERIFY_BULK, data));
    } catch (error: any) {
        setAuthorization(null);
        yield put(sendgridApiResponseError(SendGridActions.VERIFY_BULK, error));
    }
}

export function* watchVerify() {
    yield takeEvery(SendGridActions.VERIFY, verify);
}
export function* watchVerifyBulk() {
    yield takeEvery(SendGridActions.VERIFY_BULK, verifyBulk);
}
export function* watchConnect() {
    yield takeEvery(SendGridActions.CONNECT, connect);
}
export function* watchStatus() {
    yield takeEvery(SendGridActions.STATUS, status);
}

function* usersSaga() {
    yield all([
        fork(watchVerify),
        fork(watchVerifyBulk),
        fork(watchConnect),
        fork(watchStatus),
    ]);
}

export default usersSaga;
