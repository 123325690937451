import { combineReducers } from "redux";

import DigitalOcean from "./digitalocean/reducers";
import Email from "./email/reducers";
import SendGrid from "./sendgrid/reducers";

export default combineReducers({
  DigitalOcean,
  Email,
  SendGrid
});
