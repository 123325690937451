import { all } from "redux-saga/effects";

import authSaga from "./auth/saga";
import usersSaga from "./users/saga";
import layoutSaga from "./layout/saga";
import integrationsSaga from "./integrations/sagas";
import warmupSaga from "./warmup/saga";
import companiesSaga from "./companies/saga";

export default function* rootSaga() {
  yield all([authSaga(), usersSaga(), layoutSaga(), integrationsSaga(), warmupSaga(), companiesSaga()]);
}
