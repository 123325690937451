// apicore

// constants
import {EmailActions} from "./constants";

const INIT_STATE = {
  ip: "",
  reputation: -1,
  token: ""
};

interface DigitalOceanActionType {
  type:
      | EmailActions.API_RESPONSE_SUCCESS
      | EmailActions.API_RESPONSE_ERROR
      | EmailActions.EMAIL_SINGLE
      | EmailActions.EMAIL_BULK
      | EmailActions.VALIDATE_SINGLE
      | EmailActions.VALIDATE_BULK
      | EmailActions.PERMUTATE;
  payload: {
    actionType: string;
    data: any;
    error: string;
  };
}

interface State {
  ip?: string;
  reputation?: number;
  token?: string;
}

const Email = (state: State = INIT_STATE, action: DigitalOceanActionType): any => {
  switch (action.type) {
    case EmailActions.API_RESPONSE_SUCCESS:
      switch (action.payload.actionType) {
        //Actions
        case EmailActions.EMAIL_SINGLE: {
          return {
            ...state,
            loading: {
              emailSingle: false,
            },
            messages: {
              success: {
                ...((state as any)?.messages?.success ?? {}),
                emailSingle: action.payload.data.message
              },
              error: {
                ...((state as any)?.messages?.error ?? {}),
                emailSingle: ''
              }
            },
            emailSingle: action.payload.data.result
          };
        }
        case EmailActions.EMAIL_BULK: {
          return {
            ...state,
            loading: {
              emailBulk: false,
            },
            messages: {
              success: {
                ...((state as any)?.messages?.success ?? {}),
                emailBulk: action.payload.data.message
              },
              error: {
                ...((state as any)?.messages?.error ?? {}),
                emailBulk: ''
              }
            },
            emailBulk: action.payload.data.result
          };
        }
        case EmailActions.VALIDATE_SINGLE: {
          return {
            ...state,
            loading: {
              validateSingle: false,
            },
            messages: {
              success: {
                ...((state as any)?.messages?.success ?? {}),
                validateSingle: action.payload.data.message
              },
              error: {
                ...((state as any)?.messages?.error ?? {}),
                validateSingle: ''
              }
            },
            validateSingle: action.payload.data.result
          };
        }
        case EmailActions.VALIDATE_BULK: {
          return {
            ...state,
            loading: {
              validateBulk: false,
            },
            messages: {
              success: {
                ...((state as any)?.messages?.success ?? {}),
                validateBulk: action.payload.data.message
              },
              error: {
                ...((state as any)?.messages?.error ?? {}),
                validateBulk: ''
              }
            },
            validateBulk: action.payload.data.result
          };
        }
        case EmailActions.PERMUTATE: {
          return {
            ...state,
            loading: {
              permutate: false,
            },
            messages: {
              success: {
                ...((state as any)?.messages?.success ?? {}),
                permutate: action.payload.data.message
              },
              error: {
                ...((state as any)?.messages?.error ?? {}),
                permutate: ''
              }
            },
            permutate: action.payload.data.result
          };
        }
        default:
          return {
            ...state,
            loading: {
              token: false,
              rebuild: false,
              reputation: false,
            }
          };
      }

    case EmailActions.API_RESPONSE_ERROR:
      switch (action.payload.actionType) {
        case EmailActions.EMAIL_SINGLE:
          return {
            ...state,
            messages: {
              success: {
                ...((state as any)?.messages?.success ?? {}),
                emailSingle: ''
              },
              error: {
                ...((state as any)?.messages?.error ?? {}),
                emailSingle: action.payload.error.toString()
              }
            },
            loading: {
              ...(state as any).loading,
              emailSingle: false
            }
          };
        case EmailActions.EMAIL_BULK:
          return {
            ...state,
            messages: {
              success: {
                ...((state as any)?.messages?.success ?? {}),
                emailBulk: ''
              },
              error: {
                ...((state as any)?.messages?.error ?? {}),
                emailBulk: action.payload.error.toString()
              }
            },
            loading: {
              ...(state as any).loading,
              emailBulk: false
            }
          };
        case EmailActions.VALIDATE_SINGLE:
          return {
            ...state,
            messages: {
              success: {
                ...((state as any)?.messages?.success ?? {}),
                validateSingle: ''
              },
              error: {
                ...((state as any)?.messages?.error ?? {}),
                validateSingle: action.payload.error.toString()
              }
            },
            loading: {
              ...(state as any).loading,
              validateSingle: false
            }
          };
        case EmailActions.VALIDATE_BULK:
          return {
            ...state,
            messages: {
              success: {
                ...((state as any)?.messages?.success ?? {}),
                validateBulk: ''
              },
              error: {
                ...((state as any)?.messages?.error ?? {}),
                validateBulk: action.payload.error.toString()
              }
            },
            loading: {
              ...(state as any).loading,
              validateBulk: false
            }
          };
        case EmailActions.PERMUTATE:
          return {
            ...state,
            messages: {
              success: {
                ...((state as any)?.messages?.success ?? {}),
                permutate: ''
              },
              error: {
                ...((state as any)?.messages?.error ?? {}),
                permutate: action.payload.error.toString()
              }
            },
            loading: {
              ...(state as any).loading,
              permutate: false
            }
          };
        default:
          return { ...state, loading: {} };
      }

    case EmailActions.EMAIL_SINGLE:
      return { ...state, loading: {emailSingle: true}, messages: {success: {}, error: {}} };
    case EmailActions.EMAIL_BULK:
      return { ...state, loading: {emailBulk: true}, messages: {success: {}, error: {}} };
    case EmailActions.VALIDATE_SINGLE:
      return { ...state, loading: {validateSingle: true}, messages: {success: {}, error: {}} };
    case EmailActions.VALIDATE_BULK:
      return { ...state, loading: {validateBulk: true}, messages: {success: {}, error: {}} };
    case EmailActions.PERMUTATE:
      return { ...state, loading: {permutate: true}, messages: {success: {}, error: {}} };
    default:
      return { ...state, loading: {} };
  }
};

export default Email;
