import {Button, Form} from "react-bootstrap";
import classNames from "classnames";
import {Link} from "react-router-dom";
import React from "react";

const Pagination = ({currentPage, setCurrentPage, companiesCount, getPagesNum}: any) => {

    const setPage = (page: number) => {
        setCurrentPage(Math.max(Math.min(getPagesNum(), page), 1));
    }

    return <ul className="pagination pagination-rounded justify-content-end">
        <li className="page-item">
            <Button disabled={currentPage == 1} variant="link" className="page-link"
                    onClick={() => setPage(currentPage - 1)} aria-label="Previous">
                <span aria-hidden="true">«</span>
                <span className="visually-hidden">Previous</span>
            </Button>
        </li>
        <li className={classNames("page-item")}>
            <Form.Control type="number" className="d-inline" onChange={e => setPage(+e.target.value)}
                          value={currentPage} style={{width: "75px"}}></Form.Control>
            <span className="mx-2">of</span>
            <span>{getPagesNum()}</span>
        </li>

        <li className="page-item">
            <Button disabled={currentPage == getPagesNum()} variant="link" className="page-link" aria-label="Next"
                    onClick={() => setPage(currentPage + 1)}>
                <span aria-hidden="true">»</span>
                <span className="visually-hidden">Next</span>
            </Button>
        </li>
    </ul>;
}

export default Pagination;
