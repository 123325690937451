const config = {
    //API_URL: process.env.REACT_APP_API_URL,
    AUTH_SESSION_KEY: "auth_user",
    // API_URL: "http://157.230.113.121",
    API_URL: "https://sdr.api.profstep.com",
    // API_URL: "http://symfony.site",
    links: {
        //profstep
        '/home' : 'horizontal',
        '/profile/settings' : 'horizontal',

        //other
        '/dashboard-1' : 'horizontal',
        '/dashboard-2' : 'horizontal',
        '/dashboard-3' : 'horizontal',
        '/dashboard-4' : 'horizontal',
        '/app/forms/find-email' : 'horizontal',
        '/app/forms/find-bulk-emails' : 'horizontal',
        '/app/forms/validate-email' : 'horizontal',
        '/app/forms/validate-bulk-emails' : 'horizontal',
        '/app/forms/email-permutator' : 'horizontal',
        '/app/forms/validate-email-sendgrid' : 'horizontal',
        '/app/forms/validate-bulk-emails-sendgrid' : 'horizontal',
        '/apps/calendar' : 'horizontal',
        '\\/apps\\/companies\\/?' : 'horizontal',
        '\\/apps\\/companies\\/details\\/\\w+\\/?' : 'horizontal',
        '\\/apps\\/companies\\/attributes\\/?' : 'horizontal',
        '/apps/lists' : 'horizontal',
        '/apps/lists/my' : 'horizontal',
        '/apps/lists/all' : 'horizontal',
        '/apps/lists/team' : 'horizontal',
    }
};

export default config;
