import { APICore } from "../api/apiCore";
import {EmailData} from "../../redux/warmup/actions";

const api = new APICore();

type WarmupRequest = {
}

type SaveRequest = WarmupRequest & {
    emailData: EmailData;
}

// Actions
function getAll() {
    const baseUrl = "/api/warmup/getAll";
    return api.get(`${baseUrl}`, {});
}
function getInfo(id: string) {
    const baseUrl = `/api/warmup/info/${id}`;
    return api.get(`${baseUrl}`, null);
}

function save(emailData: SaveRequest) {
    const baseUrl = "/api/warmup/save";
    return api.create(`${baseUrl}`, {...emailData});
}
function deleteEmail(id: number) {
    const baseUrl = `/api/warmup/delete/${id}`;
    return api.get(`${baseUrl}`, null);
}
function run(id: number) {
    const baseUrl = `/api/warmup/run/${id}`;
    return api.create(`${baseUrl}`, {});
}
function clean(id: number) {
    const baseUrl = `/api/warmup/clean/${id}`;
    return api.get(`${baseUrl}`, {});
}
function test(email: string, id: number) {
    const baseUrl = `/api/warmup/test`;
    return api.create(`${baseUrl}`, {
        target: email,
        id
    });
}
function pause(id: number) {
    const baseUrl = `/api/warmup/pause/${id}`;
    return api.create(`${baseUrl}`, {});
}

export { getAll, save, getInfo, run, pause, deleteEmail, clean, test };
