export enum SendGridActions {
  API_RESPONSE_SUCCESS = "@@sendgrid/API_RESPONSE_SUCCESS",
  API_RESPONSE_ERROR = "@@sendgrid/API_RESPONSE_ERROR",

  //Actions
  CONNECT = "@@sendgrid/CONNECT",
  STATUS = "@@sendgrid/STATUS",
  VERIFY = "@@sendgrid/VERIFY",
  VERIFY_BULK = "@@sendgrid/VERIFY_BULK",
}
