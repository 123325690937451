import {APICore} from "../api/apiCore";
import {CustomAttributeMeta, CustomAttributesValues} from "../../redux/companies/actions";

const api = new APICore();

export type GetAllFilters = {
    page?: number;
    pageSize?: number;
    filters?: string;//base64 json
    sortBy?: string;
    sortOrder?: "ASC" | "DESC";
    customerId?: number;
}

// Actions
function getAll(setting: GetAllFilters = {}) {
    const baseUrl = "/api/companies/getAll";
    return api.get(`${baseUrl}`, setting);
}

function getInfo(id: string) {
    const baseUrl = `/api/companies/info/${id}`;
    return api.get(`${baseUrl}`, null);
}

function getCustomAttributes(id: string) {
    const baseUrl = `/api/companies/attributes/${id}`;
    return api.get(`${baseUrl}`, null);
}

function getCustomAttributesMeta() {
    const baseUrl = `/api/companies/customAttributesMeta`;
    return api.get(`${baseUrl}`, null);
}

function getAttributeTypes() {
    const baseUrl = `/api/companies/attributeTypes`;
    return api.get(`${baseUrl}`, null);
}

function upload(data: object) {
    const baseUrl = `/api/companies/upload`;
    return api.createWithFile(`${baseUrl}`, data);
}

function reindex() {
    const baseUrl = `/api/companies/reindex`;
    return api.create(`${baseUrl}`, {});
}

function saveCustomAttributes(data: CustomAttributeMeta[]) {
    const baseUrl = `/api/companies/saveCustomAttributes`;
    return api.create(`${baseUrl}`, data);
}

function saveCustomAttributesValues(data: CustomAttributesValues) {
    const baseUrl = `/api/companies/saveCustomAttributesValues`;
    return api.create(`${baseUrl}`, data);
}

function saveList(data: any) {
    const baseUrl = `/api/list/save`;
    return api.create(`${baseUrl}`, data);
}

function getUploadProgress() {
    const baseUrl = `/api/companies/upload/progress`;
    return api.get(`${baseUrl}`, null);
}

function getGroupsLayoutView() {
    const baseUrl = `/api/companies_details/get_groups`;
    return api.get(`${baseUrl}`, {});
}

function saveGroupsLayoutView(data: object) {
    const baseUrl = `/api/companies_details/save_groups`;
    return api.create(`${baseUrl}`, data);
}

function getAllListsApi(setting: GetAllFilters = {}) {
    const baseUrl = "/api/list/all";
    return api.get(`${baseUrl}`, setting);
}

function getOptionsList() {
    const baseUrl = "/api/list/options";
    return api.get(`${baseUrl}`, {});
}

export {
    getAll,
    getInfo,
    upload,
    reindex,
    getCustomAttributesMeta,
    getAttributeTypes,
    saveCustomAttributes,
    getCustomAttributes,
    saveList,
    saveCustomAttributesValues,
    getGroupsLayoutView,
    saveGroupsLayoutView,
    getAllListsApi,
    getOptionsList,
    getUploadProgress
};
