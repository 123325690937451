import {useDispatch, useSelector} from "react-redux";
import {Link} from "react-router-dom";
import {AppDispatch, RootState} from "../../../redux/store";
import {Button, Card, Col, FormCheck, Placeholder, Row} from "react-bootstrap";
import React, {useCallback, useEffect, useState} from "react";
import Table from "../../../components/Table";
import FullScreenLoader from "../../../components/ProfStep/Loader/FullScreenLoader";
import Pagination from "../../../components/ProfStep/Companies/List/Pagination";
import noResult from "../../../assets/images/companies/no-result.svg";
import {getCompanies, getCompanyLists} from "../../../redux/companies/actions";
import Filter from "../../../components/ProfStep/Companies/List/Filters/Filter";

const ActionColumn = ({row}: { row: { original: any } }) => {
    const loading: boolean = useSelector((state: RootState) => state.Warmup.loading.users ?? false);

    return <>
        <Link to={"/apps/companies/details/" + row.original.id ?? 0} hidden={loading}>
            View more info
        </Link>
    </>;
};

const BasicInfoColumn = ({row}: { row: any }) => {
    const loading: boolean = useSelector((state: RootState) => state.Warmup.loading.users ?? false);

    return (
        <>
            {(loading) && <Placeholder animation="glow">
                <Placeholder xs={6}/>
            </Placeholder>}
            {!loading && <div className="d-flex align-items-center">
                <div className="main-info mx-2">
                    <strong>{row.original.name}</strong>
                    <br/>
                    <Link to={"https://" + row.original.domain} target="_blank">
                        {row.original.domain} <span className="mdi mdi-open-in-new"></span>
                    </Link>
                </div>
            </div>}
        </>
    );
}

const SimpleColumn = ({value = ""}: { value: any }) => {
    const loading: boolean = useSelector((state: RootState) => state.Warmup.loading.users ?? false);

    return (
        <>
            {(loading) && <Placeholder animation="glow">
                <Placeholder xs={6}/>
            </Placeholder>}
            {!loading && value}
        </>
    );
}

const CheckboxColumn = ({value = ""}: { value: any }) => {
    const loading: boolean = useSelector((state: RootState) => state.Warmup.loading.users ?? false);

    return (
        <>
            {(loading) && <Placeholder animation="glow">
                <Placeholder xs={6}/>
            </Placeholder>}
            {!loading && <div className="d-flex align-items-center">
                <FormCheck name="selected" />
            </div>
            }
        </>
    );
}

const columns = [
    {
        Header: "",
        accessor: "checkbox",
        Cell: CheckboxColumn,
        sort: false,
    },
    {
        Header: "ID",
        accessor: "id",
        Cell: SimpleColumn,
        sort: true,
    },
    {
        Header: "Name",
        accessor: "name",
        Cell: SimpleColumn,
        sort: true,
    },
    {
        Header: "Description",
        accessor: "description",
        Cell: SimpleColumn,
        sort: true,
    },
    {
        Header: "User Name",
        accessor: "user_name",
        Cell: SimpleColumn,
        sort: true,
    },
];

export interface CompanyList {
    id: number;
    name: string;
    description: string;
    user_name: string;
}

interface ListProps {
    filters?: {
        assigned_user?: any
    }
}


const List = (props: ListProps) => {
    const loaders = useSelector((state: RootState) => state.Companies.loading);

    const [currentPage, setCurrentPage] = useState<number>(1);

    const dispatch = useDispatch<AppDispatch>();

    const userToken = useSelector((state: RootState) => state.Auth.user.token);
    const pageSize = useSelector((state: RootState) => state.Companies.pageSize);
    const companyListInfo = useSelector((state: RootState) => state.Companies.companyLists.data);
    const companyListCount = useSelector((state: RootState) => state.Companies.companyLists.totalCount);

    useEffect(() => {
        console.log(JSON.stringify(props.filters ?? {}))
        dispatch(getCompanyLists(userToken, {
            page: currentPage,
            pageSize: pageSize,
            filters: window.btoa(JSON.stringify(props.filters ?? {}))
        }));
    }, [currentPage]);

    const getPagesNum = useCallback(() => {
        return Math.ceil(companyListCount / pageSize);
    }, [companyListCount]);

    return <>
        <Row><Col md={12} className={"h-100"}>
                <Row>
                    <Col>
                        <Row className="justify-content-between">
                            <Col />
                            <Col>
                                <Pagination currentPage={currentPage} setCurrentPage={setCurrentPage}
                                            companyListCount={companyListCount} getPagesNum={getPagesNum}/>
                            </Col>
                        </Row>
                    </Col>
                </Row>
                <Row>
                    {!companyListCount && <Card className="mb-4 text-center">
                        <Card.Body>
                            <img src={noResult} className="mb-3 w-50 text-center" alt="No Results!"/>
                            <h4 className="text-muted mb-3">No Records Found!</h4>
                            <p>You can add new ones right now by utilizing a file upload feature located above</p>
                            <p>Or just fix your filters to match more companies ;)</p>
                        </Card.Body>
                    </Card>}
                    {!!companyListCount &&
                            <Table columns={columns}
                                   data={companyListInfo}
                                   pageSize={8}
                                   isSortable={true}
                                   tableClass="table-nowrap table-hover"
                                   searchBoxClass="my-2"/>
                    }
                </Row>
            </Col>
            <Col xs={12}>
                <div className="text-end">
                    <Pagination currentPage={currentPage} setCurrentPage={setCurrentPage} companiesCount={companyListCount}
                                getPagesNum={getPagesNum}/>
                </div>
            </Col>
        </Row>
        <FullScreenLoader visible={loaders.upload} content={"Uploading Lists..."}/>
    </>;
}

export default List;
