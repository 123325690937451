import List from "../List";
import PageTitle from "../../../../components/PageTitle";
import {useSelector} from "react-redux";
import {RootState} from "../../../../redux/store";

const ListsMy = () => {
    const userId = useSelector((state: RootState) => state.Auth.user.id);

    return (
        <>
            <PageTitle
                breadCrumbItems={[
                    { label: "Apps", path: "/apps/companies" },
                    { label: "Lists", path: "/apps/lists", active: true },
                ]}
                title={"Lists"}
            />
            <List filters={({ assigned_user: userId })}/>
        </>
    );
};

export default ListsMy;
