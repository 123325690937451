import { combineReducers } from "redux";

import Auth from "./auth/reducers";
import Layout from "./layout/reducers";
import Users from "./users/reducers";
import Integrations from "./integrations/reducers";
import Warmup from "./warmup/reducers";
import Companies from "./companies/reducers";

export default combineReducers({
  Auth,
  Users,
  Layout,
  Integrations,
  Warmup,
  Companies
});
