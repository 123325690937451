import { APICore } from "../api/apiCore";

const api = new APICore();

type DigitalOceanRequest = {
}

type ConnectRequest = DigitalOceanRequest & {
    key: string;
}

// Actions
function connect({key}: ConnectRequest) {
    const baseUrl = "/api/digitalocean/connect";
    return api.create(`${baseUrl}`,{key});
}

function rebuild() {
    const baseUrl = `/api/digitalocean/rebuild`;
    return api.get(`${baseUrl}`, {});
}

function getData() {
    const baseUrl = `/api/digitalocean/status`;
    return api.get(`${baseUrl}`,{});
}

export const REPUTATION_INFO = "@@digitalocean/REPUTATION_INFO";

function setSessionInfo(info: any) {
    return localStorage.setItem(REPUTATION_INFO, JSON.stringify(info));
}

function getSessionInfo() {
    return JSON.parse(localStorage.getItem(REPUTATION_INFO) ?? '{"reputation": -1}');
}


export { connect, rebuild, getData, setSessionInfo, getSessionInfo };