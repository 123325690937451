import { all, fork, put, takeEvery, call } from "redux-saga/effects";
import { SagaIterator } from "@redux-saga/core";

// apicore
import {APICore, getAuthorization, setAuthorization} from "../../helpers/api/apiCore";

// helpers
import {
  allUsers as allUsersApi,
  userById as userByIdApi,
  updateUser as updateUserApi,
  deleteUser as deleteUserApi,
} from "../../helpers/";

// actions
import { usersApiResponseSuccess, usersApiResponseError } from "./actions";

// constants
import { UsersActionTypes } from "./constants";

interface RequestData {
  payload: {
    token: string;
    id?: string;
    userData?: object;
    isEdit?: boolean
  };
  type: string;
}

const api = new APICore();

/**
 * Login the user
 * @param {*} payload - username and password
 */
function* getUsers({
    payload: {token},
    type,
}: RequestData): SagaIterator {
  try {
    if (!getAuthorization()) {
      setAuthorization(token);
    }
    const response = yield call(allUsersApi);
    const data = response.data;
    // NOTE - You can change this according to response format from your api
    yield put(usersApiResponseSuccess(UsersActionTypes.GET_USERS, data));
  } catch (error: any) {
    setAuthorization(null);
    yield put(usersApiResponseError(UsersActionTypes.GET_USERS, error));
  }
}

function* updateUser({
    payload: {token, userData},
    type,
}: RequestData): SagaIterator {
  try {
    if (!getAuthorization()) {
      setAuthorization(token);
    }

    const id = (userData as any)?.id as number;
    const response = yield call(() => updateUserApi({userData: (userData as object), id}));
    const data = response.data;
    // NOTE - You can change this according to response format from your api
    yield put(usersApiResponseSuccess(UsersActionTypes.UPDATE_USER, {
      userData,
      response: data
    }));
  } catch (error: any) {
    setAuthorization(null);
    yield put(usersApiResponseError(UsersActionTypes.UPDATE_USER, error));
  }
}

function* deleteUser({
    payload: {token, id},
    type,
}: RequestData): SagaIterator {
  try {
    if (!getAuthorization()) {
      setAuthorization(token);
    }

    const response = yield call(() => deleteUserApi({id: +(id ?? 0)}));
    const data = response.data;
    // NOTE - You can change this according to response format from your api
    yield put(usersApiResponseSuccess(UsersActionTypes.DELETE_USER, {
      id,
      response: data
    }));
  } catch (error: any) {
    setAuthorization(null);
    yield put(usersApiResponseError(UsersActionTypes.DELETE_USER, error));
  }
}

/**
 * Logout the user
 */
function* getById({
    payload: {
      id,
      token
    }
}: RequestData): SagaIterator {
  try {
    if (!getAuthorization()) {
      setAuthorization(token);
    }
    const response = yield call(() => userByIdApi({id} as {id: string}));
    const userData = response.data;

    yield put(usersApiResponseSuccess(UsersActionTypes.GET_USER_BY_ID, userData));
  } catch (error: any) {
    yield put(usersApiResponseError(UsersActionTypes.GET_USER_BY_ID, error));
  }
}

function* setUser({
    payload: {
      userData
    }
}: RequestData): SagaIterator {
  try {
    userData = userData ?? {};
    yield put(usersApiResponseSuccess(UsersActionTypes.SET_USER, userData));
  } catch (error: any) {
    yield put(usersApiResponseError(UsersActionTypes.SET_USER, error));
  }
}
function* setEdit({
    payload: {
      isEdit
    }
}: RequestData): SagaIterator {
  try {
    yield put(usersApiResponseSuccess(UsersActionTypes.SET_EDIT, !!isEdit));
  } catch (error: any) {
    yield put(usersApiResponseError(UsersActionTypes.SET_EDIT, error));
  }
}

export function* watchGetUsers() {
  yield takeEvery(UsersActionTypes.GET_USERS, getUsers);
}
export function* watchSetUser() {
  yield takeEvery(UsersActionTypes.SET_USER, setUser);
}
export function* watchSetEdit() {
  yield takeEvery(UsersActionTypes.SET_EDIT, setEdit);
}
export function* watchUpdateUser() {
  yield takeEvery(UsersActionTypes.UPDATE_USER, updateUser);
}
export function* watchDeleteUser() {
  yield takeEvery(UsersActionTypes.DELETE_USER, deleteUser);
}

export function* watchGetUserById() {
  yield takeEvery(UsersActionTypes.GET_USER_BY_ID, getById);
}

function* usersSaga() {
  yield all([
    fork(watchGetUsers),
    fork(watchSetUser),
    fork(watchSetEdit),
    fork(watchUpdateUser),
    fork(watchDeleteUser),
    fork(watchGetUserById),
  ]);
}

export default usersSaga;
