import { APICore } from "./apiCore";

const api = new APICore();

// account
function login(params: { username: string; password: string }) {
  const baseUrl = "/user/login";
  return api.create(`${baseUrl}`, params);
}

function logout() {
  const baseUrl = "/logout";
  return api.create(`${baseUrl}`, {});
}

function signup(params: { lastname: string; firstname: string; username: string; email: string; password: string }) {
  const baseUrl = "/user/register";
  return api.create(`${baseUrl}`, params);
}

function forgotPassword(params: { username: string }) {
  const baseUrl = "/api/forget-password";
  return api.create(`${baseUrl}`, params);
}

export { login, logout, signup, forgotPassword };
