import { APICore } from "../api/apiCore";
import {EmailData} from "../../redux/warmup/actions";

const api = new APICore();

// Actions
function getView(customerId: number) {
    const baseUrl = `/api/companies/getView/${customerId}`;
    return api.get(`${baseUrl}`, null);
}
function saveView(customerId: number, data: string) {
    const baseUrl = `/api/companies/saveView/${customerId}`;
    return api.create(`${baseUrl}`, data);
}

export { getView, saveView };
