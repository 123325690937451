import { all, fork, put, takeEvery, call } from "redux-saga/effects";
import { SagaIterator } from "@redux-saga/core";

// apicore
import {APICore, getAuthorization, setAuthorization} from "../../helpers/api/apiCore";

// helpers
import {
    getAll as getAllApi,
    save as saveApi,
    deleteEmail as deleteEmailApi,
    run as runApi,
    pause as pauseApi,
    getInfo as getInfoApi,
    clean as cleanApi,
    test as testApi,
    getSettings as getSettingsApi,
    saveSettings as saveSettingsApi,
    removeFromSpam as removeFromSpamApi,
} from "../../helpers/warmup";

// actions
import {warmupApiResponseSuccess, warmupApiResponseError, EmailData} from "./actions";

// constants
import { WarmupActionTypes } from "./constants";
import {SettingsData} from "../../pages/profstep/warmup/settings";

interface RequestData {
    payload: {
        token: string;
    };
    type: string;
}

type UserInfoData = RequestData & {
    payload: {
        id: string
    }
};

type SaveData = RequestData & {
    payload: {
        emailData: EmailData
    }
};

type RunData = RequestData & {
    payload: {
        id: number
    }
};

type TestData = RequestData & {
    payload: {
        email: string;
        id: number;
    }
};

type SaveSettingsData = RequestData & {
    payload: {
        settings: SettingsData
    }
};

/**
 * Get all users data.
 */
function* getAll({
    payload: {
        token
    }
}: RequestData): SagaIterator {
    try {
        if (!getAuthorization()) {
            setAuthorization(token);
        }

        const response = yield call(() => getAllApi());
        yield put(warmupApiResponseSuccess(WarmupActionTypes.GET_ALL, response.data));
    } catch (error: any) {
        yield put(warmupApiResponseError(WarmupActionTypes.GET_ALL, error));
    }
}

/**
 * Get specific user data.
 */
function* getInfo({
    payload: {
        token,
        id
    }
}: UserInfoData): SagaIterator {
    try {
        if (!getAuthorization()) {
            setAuthorization(token);
        }

        const response = yield call(() => getInfoApi(id));
        yield put(warmupApiResponseSuccess(WarmupActionTypes.GET_INFO, response.data));
    } catch (error: any) {
        yield put(warmupApiResponseError(WarmupActionTypes.GET_INFO, error));
    }
}

/**
 * Get all users data.
 */
function* save({
    payload: {
        token,
        emailData
    }
}: SaveData): SagaIterator {
    try {
        if (!getAuthorization()) {
            setAuthorization(token);
        }

        const response = yield call(() => saveApi({emailData}));
        yield put(warmupApiResponseSuccess(WarmupActionTypes.SAVE, response.data));


    } catch (error: any) {
        yield put(warmupApiResponseError(WarmupActionTypes.SAVE, error));
    }
}

/**
 * Run warmup.
 */
function* run({
    payload: {
        token,
        id
    }
}: RunData): SagaIterator {
    try {
        if (!getAuthorization()) {
            setAuthorization(token);
        }

        const response = yield call(() => runApi(id));
        yield put(warmupApiResponseSuccess(WarmupActionTypes.RUN, response.data));


    } catch (error: any) {
        yield put(warmupApiResponseError(WarmupActionTypes.RUN, error));
    }
}
/**
 * Clean warmup.
 */
function* clean({
    payload: {
        token,
        id
    }
}: RunData): SagaIterator {
    try {
        if (!getAuthorization()) {
            setAuthorization(token);
        }

        const response = yield call(() => cleanApi(id));
        yield put(warmupApiResponseSuccess(WarmupActionTypes.CLEAN, response.data));


    } catch (error: any) {
        yield put(warmupApiResponseError(WarmupActionTypes.CLEAN, error));
    }
}

/**
 * Clean warmup.
 */
function* test({
    payload: {
        token,
        email,
        id
    }
}: TestData): SagaIterator {
    try {
        if (!getAuthorization()) {
            setAuthorization(token);
        }

        const response = yield call(() => testApi(email, id));
        yield put(warmupApiResponseSuccess(WarmupActionTypes.TEST, response.data));
    } catch (error: any) {
        yield put(warmupApiResponseError(WarmupActionTypes.TEST, error));
    }
}

/**
 * Run warmup.
 */
function* deleteEmail({
    payload: {
        token,
        id
    }
}: RunData): SagaIterator {
    try {
        if (!getAuthorization()) {
            setAuthorization(token);
        }

        const response = yield call(() => deleteEmailApi(id));
        yield put(warmupApiResponseSuccess(WarmupActionTypes.DELETE, response.data));


    } catch (error: any) {
        yield put(warmupApiResponseError(WarmupActionTypes.DELETE, error));
    }
}

/**
 * Run warmup.
 */
function* pause({
    payload: {
        token,
        id
    }
}: RunData): SagaIterator {
    try {
        if (!getAuthorization()) {
            setAuthorization(token);
        }

        const response = yield call(() => pauseApi(id));
        yield put(warmupApiResponseSuccess(WarmupActionTypes.PAUSE, response.data));


    } catch (error: any) {
        yield put(warmupApiResponseError(WarmupActionTypes.PAUSE, error));
    }
}

/**
 * Get warmup settings.
 */
function* getSettings({
    payload: {
        token
    }
}: RequestData): SagaIterator {
    try {
        if (!getAuthorization()) {
            setAuthorization(token);
        }

        const response = yield call(getSettingsApi);
        yield put(warmupApiResponseSuccess(WarmupActionTypes.GET_SETTINGS, response.data));
    } catch (error: any) {
        yield put(warmupApiResponseError(WarmupActionTypes.GET_SETTINGS, error));
    }
}

/**
 * Remove messages from spam.
 */
function* removeFromSpam({
    payload: {
        token
    }
}: RequestData): SagaIterator {
    try {
        if (!getAuthorization()) {
            setAuthorization(token);
        }

        const response = yield call(removeFromSpamApi);
        yield put(warmupApiResponseSuccess(WarmupActionTypes.REMOVE_FROM_SPAM, response.data));
    } catch (error: any) {
        yield put(warmupApiResponseError(WarmupActionTypes.REMOVE_FROM_SPAM, error));
    }
}

/**
 * Save warmup settings.
 */
function* saveSettings({
    payload: {
        token,
        settings
    }
}: SaveSettingsData): SagaIterator {
    try {
        if (!getAuthorization()) {
            setAuthorization(token);
        }

        const response = yield call(() => saveSettingsApi({settings}));
        yield put(warmupApiResponseSuccess(WarmupActionTypes.SAVE_SETTINGS, response.data));


    } catch (error: any) {
        yield put(warmupApiResponseError(WarmupActionTypes.SAVE_SETTINGS, error));
    }
}

export function* watchGetAll() {
    yield takeEvery(WarmupActionTypes.GET_ALL, getAll);
}
export function* watchSave() {
    yield takeEvery(WarmupActionTypes.SAVE, save);
    yield takeEvery(WarmupActionTypes.SAVE, getAll);
}

export function* watchRun() {
    yield takeEvery(WarmupActionTypes.RUN, run);
    yield takeEvery(WarmupActionTypes.RUN, getInfo);
}

export function* watchDeleteEmail() {
    yield takeEvery(WarmupActionTypes.DELETE, deleteEmail);
}

export function* watchPause() {
    yield takeEvery(WarmupActionTypes.PAUSE, pause);
}
export function* watchClean() {
    yield takeEvery(WarmupActionTypes.CLEAN, clean);
}
export function* watchTest() {
    yield takeEvery(WarmupActionTypes.TEST, test);
}

export function* watchGetInfo() {
    yield takeEvery(WarmupActionTypes.GET_INFO, getInfo);
}

export function* watchSaveSettings() {
    yield takeEvery(WarmupActionTypes.SAVE_SETTINGS, saveSettings);
}
export function* watchGetSettings() {
    yield takeEvery(WarmupActionTypes.GET_SETTINGS, getSettings);
}
export function* watchRemoveFromSpam() {
    yield takeEvery(WarmupActionTypes.REMOVE_FROM_SPAM, removeFromSpam);
}

function* warmupSaga() {
    yield all([
        fork(watchGetAll),
        fork(watchSave),
        fork(watchGetInfo),
        fork(watchRun),
        fork(watchDeleteEmail),
        fork(watchPause),
        fork(watchClean),
        fork(watchTest),
        fork(watchSaveSettings),
        fork(watchGetSettings),
        fork(watchRemoveFromSpam),
    ]);
}

export default warmupSaga;
