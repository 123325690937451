// constants
import { SendGridActions } from "./constants";

export interface SendGridActionType {
    type:
        | SendGridActions.API_RESPONSE_SUCCESS
        | SendGridActions.API_RESPONSE_ERROR
        | SendGridActions.CONNECT
        | SendGridActions.STATUS
        | SendGridActions.VERIFY
        | SendGridActions.VERIFY_BULK;
    payload: {} | string;
}

// common success
export const sendgridApiResponseSuccess = (
    actionType: string,
    data: any
): SendGridActionType => ({
    type: SendGridActions.API_RESPONSE_SUCCESS,
    payload: {actionType, data}
});
// common error
export const sendgridApiResponseError = (
    actionType: string,
    error: string
): SendGridActionType => ({
    type: SendGridActions.API_RESPONSE_ERROR,
    payload: { actionType, error },
});

//Actions
export const verify = (
    token: string,
    email: string
): SendGridActionType => ({
    type: SendGridActions.VERIFY,
    payload: { token, email },
});
export const verifyBulk = (
    token: string,
    emails: string
): SendGridActionType => ({
    type: SendGridActions.VERIFY_BULK,
    payload: { token, emails },
});

export const connectSg = (
    token: string,
    keys: {
        [key: string]: string
    }
): SendGridActionType => ({
    type: SendGridActions.CONNECT,
    payload: { token, keys },
});

export const sgStatus = (
    token: string
): SendGridActionType => ({
    type: SendGridActions.STATUS,
    payload: { token },
});
