import loader from '../../../assets/images/loader/logo_loader.svg';
import classNames from "classnames";

type LoaderProps = {
    visible: boolean;
    content: string;
}

const FullScreenLoader = ({visible, content}: LoaderProps) => {
    return <div className={classNames("loader full-screen-loader", {
        "hidden": !visible
    })}>
        <img src={loader} className="loader-img" alt="loader"/>
        {content && <h4 className="loader-description">{content}</h4>}
    </div>;
}

export default FullScreenLoader;
