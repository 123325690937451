export enum EmailActions {
  API_RESPONSE_SUCCESS = "@@email/API_RESPONSE_SUCCESS",
  API_RESPONSE_ERROR = "@@email/API_RESPONSE_ERROR",

  //Actions
  EMAIL_SINGLE = "@@email/EMAIL_SINGLE",
  EMAIL_BULK = "@@email/EMAIL_BULK",
  VALIDATE_SINGLE = "@@email/VALIDATE_SINGLE",
  VALIDATE_BULK = "@@email/VALIDATE_BULK",
  PERMUTATE = "@@email/PERMUTATE",
}
