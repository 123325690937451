import { all, fork, put, takeEvery, call, delay } from "redux-saga/effects";
import { SagaIterator } from "@redux-saga/core";

// helpers
import {
  rebuild as rebuildApi,
  connect as connectApi,
  getData as getDataApi,
  setSessionInfo,
} from "../../../helpers/integrations";

// actions
import { usersApiResponseSuccess, usersApiResponseError } from "./actions";

// constants
import { DigitalOceanActions } from "./constants";
import {getAuthorization, setAuthorization} from "../../../helpers/api/apiCore";

interface RequestData {
  payload: {
    token: string;
  };
  type: string;
}

type ConnectRequestData = RequestData & {
  payload: {
    key: string;
  }
}
type EmailRequestData = RequestData & {
  payload: {
    userData: {
      firstName: string;
      lastName: string;
      domain: string;
    }
  }
}

/**
 * Connect to server.
 *
 * @param {*} payload - username and password
 */
function* connect({
    payload: {token, key},
}: ConnectRequestData): SagaIterator {
  try {
    if (!getAuthorization()) {
      setAuthorization(token);
    }
    const response = yield call(() => connectApi({key}));
    const data = response.data;

    yield put(usersApiResponseSuccess(DigitalOceanActions.CONNECT, data));
  } catch (error: any) {
    setAuthorization(null);
    yield put(usersApiResponseError(DigitalOceanActions.CONNECT, error));
  }
}

/**
 * Rebuild instance.
 *
 * @param {*} payload - username and password
 */
function* rebuild({
    payload: {token},
}: ConnectRequestData): SagaIterator {
  try {
    if (!getAuthorization()) {
      setAuthorization(token);
    }
    const response = yield call(rebuildApi);

    yield delay(60000);

    yield put(usersApiResponseSuccess(DigitalOceanActions.REBUILD, response.data));

    try {
      const data = yield call(getDataApi);
      yield put(usersApiResponseSuccess(DigitalOceanActions.GET_DATA, data));
    } catch (e: any) {
      yield put(usersApiResponseError(DigitalOceanActions.GET_DATA, e));
    }
  } catch (error: any) {
    setAuthorization(null);
    yield put(usersApiResponseError(DigitalOceanActions.REBUILD, error));
  }
}

/**
 * Rebuild instance.
 *
 * @param {*} payload - username and password
 */
function* getData({
    payload: {token},
}: ConnectRequestData): SagaIterator {
  try {
    if (!getAuthorization()) {
      setAuthorization(token);
    }
    const response = yield call(getDataApi);
    const data = response.data;

    setSessionInfo({
      reputation: data.reputation,
      total: data['total'],
      ip: data['ip'],
      totalSuccess: data['total_success'],
      totalBlocked: data['total_blocked'],
      token: data.token,
    });

    yield put(usersApiResponseSuccess(DigitalOceanActions.GET_DATA, data));
  } catch (error: any) {
    setAuthorization(null);
    yield put(usersApiResponseError(DigitalOceanActions.GET_DATA, error));
  }
}

export function* watchConnect() {
  yield takeEvery(DigitalOceanActions.CONNECT, connect);
}
export function* watchRebuild() {
  yield takeEvery(DigitalOceanActions.REBUILD, rebuild);
}
export function* watchGetData() {
  yield takeEvery(DigitalOceanActions.GET_DATA, getData);
}

function* usersSaga() {
  yield all([
    fork(watchConnect),
    fork(watchRebuild),
    fork(watchGetData),
  ]);
}

export default usersSaga;
