// @ts-ignore

import {all, fork, put, takeEvery, call} from "redux-saga/effects";
import {SagaIterator} from "@redux-saga/core";
// apicore
import {getAuthorization, setAuthorization} from "../../helpers/api/apiCore";

// helpers
import {
    getAll as getAllApi,
    upload as uploadApi,
    reindex as reindexApi,
    saveCustomAttributes as saveCustomAttributesApi,
    getCustomAttributesMeta as getCustomAttributesMetaApi,
    getAttributeTypes as getAttributeTypesApi,
    getInfo as getInfoApi,
    getView as getViewApi,
    saveView as saveViewApi,
    saveList as saveListApi,
    saveCustomAttributesValues as saveCustomAttributesValuesApi,
    getUploadProgress as getUploadProgressApi,
    getGroupsLayoutView as getGroupsLayoutViewApi,
    saveGroupsLayoutView as saveGroupsLayoutViewApi,
    getOptionsList as getOptionsListApi
} from "../../helpers/companies";

// actions
import {
    companiesApiResponseSuccess,
    companiesApiResponseError,
    getCompaniesCustomAttributesMeta,
    CustomAttributesValues
} from "./actions";

// constants
import {CompaniesActionTypes} from "./constants";
import {GetAllFilters, getAllListsApi} from "../../helpers/companies/general";

interface RequestData {
    payload: {
        token: string;
    };
    type: string;
}

type GetAllData = RequestData & {
    payload: {
        settings: GetAllFilters
    }
};

type GetInfoData = RequestData & {
    payload: {
        id: string;
    }
};

type UploadData = RequestData & {
    payload: {
        data: object
    }
};

type SaveCustomAttributesData = RequestData & {
    payload: {
        data: any[]
    }
};

type SaveCustomAttributesValues = RequestData & {
    payload: {
        data: CustomAttributesValues
    }
}

interface FiltersData extends RequestData {
    payload: {
        token: string;
        customerId: number;
    };
    type: string;
}

type SaveFiltersData = FiltersData & {
    payload: {
        data: any
    }
}

/**
 * Get all users data.
 */
function* getAll({
    payload: {
         token,
         settings
    }
}: GetAllData): SagaIterator {
    try {
        if (!getAuthorization()) {
            setAuthorization(token);
        }

        const response = yield call(() => getAllApi(settings));
        yield put(companiesApiResponseSuccess(CompaniesActionTypes.GET_ALL, response.data));
    } catch (error: any) {
        yield put(companiesApiResponseError(CompaniesActionTypes.GET_ALL, error));
    }
}

/**
 * Get specific user data.
 */
function* getInfo({
    payload: {
        token,
        id
    }
}: GetInfoData): SagaIterator {
    try {
        if (!getAuthorization()) {
            setAuthorization(token);
        }

        const response = yield call(() => getInfoApi(id));
        yield put(companiesApiResponseSuccess(CompaniesActionTypes.GET_INFO, response.data));
    } catch (error: any) {
        yield put(companiesApiResponseError(CompaniesActionTypes.GET_INFO, error));
    }
}

/**
 * Upload companies CSVs.
 */
function* upload({
    payload: {
        token,
        data
    }
}: UploadData): SagaIterator {
    try {
        if (!getAuthorization()) {
            setAuthorization(token);
        }

        const response = yield call(() => uploadApi(data));
        yield put(companiesApiResponseSuccess(CompaniesActionTypes.UPLOAD, response.data));


    } catch (error: any) {
        yield put(companiesApiResponseError(CompaniesActionTypes.UPLOAD, error));
    }
}

/**
 * Reindex companies EAV.
 */
function* reindex({
    payload: {
        token
    }
}: RequestData): SagaIterator {
    try {
        if (!getAuthorization()) {
            setAuthorization(token);
        }

        const response = yield call(() => reindexApi());
        yield put(companiesApiResponseSuccess(CompaniesActionTypes.REINDEX, response.data));

    } catch (error: any) {
        yield put(companiesApiResponseError(CompaniesActionTypes.REINDEX, error));
    }
}

/**
 * Reindex companies EAV.
 */
function* getCompanyLists({
    payload: {
        token
    }
}: RequestData): SagaIterator {
    try {
        if (!getAuthorization()) {
            setAuthorization(token);
        }

        const response = yield call(() => reindexApi());
        yield put(companiesApiResponseSuccess(CompaniesActionTypes.REINDEX, response.data));

    } catch (error: any) {
        yield put(companiesApiResponseError(CompaniesActionTypes.REINDEX, error));
    }
}

/**
 * Reindex companies EAV.
 */
function* saveList({
    payload: {
        token,
        data
    }
}: UploadData): SagaIterator {
    try {
        if (!getAuthorization()) {
            setAuthorization(token);
        }

        const response = yield call(() => saveListApi(data));
        yield put(companiesApiResponseSuccess(CompaniesActionTypes.SAVE_LIST, response.data));
    } catch (error: any) {
        yield put(companiesApiResponseError(CompaniesActionTypes.SAVE_LIST, error));
    }
}

/**
 * Add companies to existent list.
 */
function* addToList({
    payload: {
        token,
        data
    }
}: UploadData): SagaIterator {
    try {
        if (!getAuthorization()) {
            setAuthorization(token);
        }

        const response = yield call(() => saveListApi(data));
        yield put(companiesApiResponseSuccess(CompaniesActionTypes.SAVE_LIST, response.data));
    } catch (error: any) {
        yield put(companiesApiResponseError(CompaniesActionTypes.SAVE_LIST, error));
    }
}

/**
 * Get all users data.
 */
function* getAllLists({
                     payload: {
                         token,
                         settings
                     }
                 }: GetAllData): SagaIterator {
    try {
        if (!getAuthorization()) {
            setAuthorization(token);
        }

        const response = yield call(() => getAllListsApi(settings));
        yield put(companiesApiResponseSuccess(CompaniesActionTypes.GET_ALL_LISTS, response.data));
    } catch (error: any) {
        yield put(companiesApiResponseError(CompaniesActionTypes.GET_ALL_LISTS, error));
    }
}

/**
 * Reindex companies EAV.
 */
function* saveCustomAttributes({
                                   payload: {
                                       token,
                                       data
                                   }
                               }: SaveCustomAttributesData): SagaIterator {
    try {
        if (!getAuthorization()) {
            setAuthorization(token);
        }

        const response = yield call(() => saveCustomAttributesApi(data));

        yield put(getCompaniesCustomAttributesMeta(token));
        yield put(companiesApiResponseSuccess(CompaniesActionTypes.SAVE_CUSTOM_ATTRIBUTES, response.data));
    } catch (error: any) {
        yield put(companiesApiResponseError(CompaniesActionTypes.SAVE_CUSTOM_ATTRIBUTES, error));
    }
}

/**
 * Reindex companies EAV.
 */
function* saveCustomAttributesValues({
                                         payload: {
                                             token,
                                             data
                                         }
                                     }: SaveCustomAttributesValues): SagaIterator {
    try {
        if (!getAuthorization()) {
            setAuthorization(token);
        }

        const response = yield call(() => saveCustomAttributesValuesApi(data));

        yield put(companiesApiResponseSuccess(CompaniesActionTypes.SAVE_CUSTOM_ATTRIBUTES_VALUES, response.data));
    } catch (error: any) {
        yield put(companiesApiResponseError(CompaniesActionTypes.SAVE_CUSTOM_ATTRIBUTES_VALUES, error));
    }
}

/**
 * Get filters view.
 */
function* getView({
    payload: {
        token,
        customerId
    }
}: FiltersData): SagaIterator {
    try {
        if (!getAuthorization()) {
            setAuthorization(token);
        }

        const response = yield call(() => getViewApi(customerId));
        yield put(companiesApiResponseSuccess(CompaniesActionTypes.GET_VIEW, response.data));
    } catch (error: any) {
        yield put(companiesApiResponseError(CompaniesActionTypes.GET_VIEW, error));
    }
}

/**
 * Reindex companies EAV.
 */
function* getCustomAttributesMeta({
    payload: {
        token,
    }
}: RequestData): SagaIterator {
    try {
        if (!getAuthorization()) {
            setAuthorization(token);
        }

        const customAttributesMeta = yield call(() => getCustomAttributesMetaApi());
        const attributeTypes = yield call(() => getAttributeTypesApi());

        yield put(companiesApiResponseSuccess(CompaniesActionTypes.GET_CUSTOM_ATTRIBUTES_META, {
            customAttributesMeta: customAttributesMeta.data,
            attributeTypes: attributeTypes.data
        }));
    } catch (error: any) {
        yield put(companiesApiResponseError(CompaniesActionTypes.GET_CUSTOM_ATTRIBUTES_META, error));
    }
}

/**
 * Retrieve all groups layout view.
 */
function* getGroupsLayoutView({
    payload: {
        token,
    }
}: RequestData): SagaIterator {
    try {
        if (!getAuthorization()) {
            setAuthorization(token);
        }

        const response = yield call(() => getGroupsLayoutViewApi());
        yield put(companiesApiResponseSuccess(CompaniesActionTypes.GET_GROUPS_LAYOUT_VIEW, response.data));
    } catch (error: any) {
        yield put(companiesApiResponseError(CompaniesActionTypes.GET_GROUPS_LAYOUT_VIEW, error));
    }
}

function* getOptionsList({
     payload: {
         token,
     }
 }: RequestData): SagaIterator {
    try {
        if (!getAuthorization()) {
            setAuthorization(token);
        }

        const response = yield call(() => getOptionsListApi());
        yield put(companiesApiResponseSuccess(CompaniesActionTypes.GET_OPTIONS_LIST, response.data));
    } catch (error: any) {
        yield put(companiesApiResponseError(CompaniesActionTypes.GET_OPTIONS_LIST, error));
    }
}

function* getUploadProgress({
    payload: {
        token
    }
}: RequestData): SagaIterator {
    try {
        if (!getAuthorization()) {
            setAuthorization(token);
        }

        const response = yield call(() => getUploadProgressApi());

        yield put(companiesApiResponseSuccess(CompaniesActionTypes.GET_UPLOAD_PROGRESS, response.data));
    } catch (error: any) {
        yield put(companiesApiResponseError(CompaniesActionTypes.GET_OPTIONS_LIST, error));
    }
}

/**
 * Get filters view.
 */
function* saveView({
    payload: {
        token,
        customerId,
        data
    }
}: SaveFiltersData): SagaIterator {
    try {
        if (!getAuthorization()) {
            setAuthorization(token);
        }

        const response = yield call(() => saveViewApi(customerId, btoa(JSON.stringify(data))));
        yield put(companiesApiResponseSuccess(CompaniesActionTypes.SAVE_VIEW, response.data));
    } catch (error: any) {
        yield put(companiesApiResponseError(CompaniesActionTypes.SAVE_VIEW, error));
    }
}

/**
 * Get filters view.
 */
function* saveGroupsLayoutView({
    payload: {
        token,
        data
    }
}: SaveFiltersData): SagaIterator {
    try {
        if (!getAuthorization()) {
            setAuthorization(token);
        }

        const response = yield call(() => saveGroupsLayoutViewApi(data));

        yield put(companiesApiResponseSuccess(CompaniesActionTypes.SAVE_GROUPS_LAYOUT_VIEW, response.data));
    } catch (error: any) {
        yield put(companiesApiResponseError(CompaniesActionTypes.SAVE_GROUPS_LAYOUT_VIEW, error));
    }
}

export function* watchGetAll() {
    yield takeEvery(CompaniesActionTypes.GET_ALL, getAll);
}

export function* watchGetAllLists() {
    yield takeEvery(CompaniesActionTypes.GET_ALL_LISTS, getAllLists);
}

export function* watchUpload() {
    yield takeEvery(CompaniesActionTypes.UPLOAD, upload);
}

export function* watchReindex() {
    yield takeEvery(CompaniesActionTypes.REINDEX, reindex);
}

export function* watchGetInfo() {
    yield takeEvery(CompaniesActionTypes.GET_INFO, getInfo);
}

export function* watchSaveCustomAttributes() {
    yield takeEvery(CompaniesActionTypes.SAVE_CUSTOM_ATTRIBUTES, saveCustomAttributes);
}

export function* watchSaveCustomAttributesValues() {
    yield takeEvery(CompaniesActionTypes.SAVE_CUSTOM_ATTRIBUTES_VALUES, saveCustomAttributesValues);
}

export function* watchGetCustomAttributesMeta() {
    yield takeEvery(CompaniesActionTypes.GET_CUSTOM_ATTRIBUTES_META, getCustomAttributesMeta);
}

//Filters View
export function* watchGetView() {
    yield takeEvery(CompaniesActionTypes.GET_VIEW, getView);
}

//Filters View
export function* watchGetOptionsList() {
    yield takeEvery(CompaniesActionTypes.GET_OPTIONS_LIST, getOptionsList);
}

export function* watchSaveView() {
    yield takeEvery(CompaniesActionTypes.SAVE_VIEW, saveView);
}

export function* watchSaveList() {
    yield takeEvery(CompaniesActionTypes.SAVE_LIST, saveList);
}

export function* watchAddToList() {
    yield takeEvery(CompaniesActionTypes.ADD_TO_LIST, addToList);
}

export function* watchGetUploadProgress() {
    yield takeEvery(CompaniesActionTypes.GET_UPLOAD_PROGRESS, getUploadProgress);
}

export function* watchGetGroupsLayoutView() {
    yield takeEvery(CompaniesActionTypes.GET_GROUPS_LAYOUT_VIEW, getGroupsLayoutView);
}

export function* watchSaveGroupsLayoutView() {
    yield takeEvery(CompaniesActionTypes.SAVE_GROUPS_LAYOUT_VIEW, saveGroupsLayoutView);
}

function* companiesSaga() {
    yield all([
        fork(watchGetAll),
        fork(watchGetInfo),
        fork(watchUpload),
        fork(watchReindex),
        fork(watchSaveCustomAttributes),
        fork(watchGetCustomAttributesMeta),
        fork(watchGetView),
        fork(watchSaveView),
        fork(watchSaveList),
        fork(watchAddToList),
        fork(watchSaveCustomAttributesValues),
        fork(watchGetUploadProgress),
        fork(watchGetGroupsLayoutView),
        fork(watchGetAllLists),
        fork(watchSaveGroupsLayoutView),
        fork(watchGetOptionsList)
    ]);
}

export default companiesSaga;
