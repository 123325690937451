// apicore
import { APICore } from "../../helpers/api/apiCore";

// constants
import {ActionTypes, CompaniesActionTypes, PAGE_SIZE} from "./constants";
import {CompanyData, CustomAttributeMeta} from "./actions";
import {DateTime} from "../store";

const api = new APICore();

export type CustomAttributesMetaState = {
    customAttributesMeta: CustomAttributeMeta[],
    attributeTypes: string[]
}

export type CompanyUploadProgress = {
    "file_name": string;
    "line_read": number;
    "lines_count": number;
    "column_types": string;//JSON
    "additional_data": string;//JSON
    "upload_time": DateTime;//datetime
}

const INIT_STATE = {
    loading: {},
    messages: {
        success: {},
        error: {},
    },
    companies: {
        data: [],
        totalCount: 0
    },
    companyLists: {
        data: [],
        totalCount: 0
    },
    customAttributesMeta: {
        customAttributesMeta: [],
        attributeTypes: []
    },
    filtersView: [],
    pageSize: +(JSON.parse(localStorage.getItem("companies.pageSize") ?? <string><unknown>PAGE_SIZE)),
    sortBy: localStorage.getItem("companies.sortBy") ?? '',
    sortOrder: localStorage.getItem("companies.sortOrder") ?? 'ASC',
    groupsLayoutView: [],
    upload: []
};

type ResponseMessages = {
    [key in "success"|"error"]: {};
}

export type ViewConfig = {
    attribute_code: string;
    input_type: string;
    is_filterable: boolean;
    is_sortable: boolean;
    is_visible_in_listing: boolean;
    label: string;
    code: string;
    additional_data?: {
        options?: string[]
    }
}

interface ResultData {
    messages: ResponseMessages;
    result: any;
    [key: string]: any;
}

interface CompaniesActionType {
    type: ActionTypes;
    payload: {
        actionType?: string;
        data: ResultData;
        error?: string;
        [key: string]: any;
    };
}

interface State {
    stats?: any;
    users?: Array<any>;
    loading: {},
    messages: ResponseMessages,
    companies: {
        data: CompanyData[],
        totalCount: number
    },
    customAttributesMeta: CustomAttributesMetaState;
    filtersView: ViewConfig[];
    pageSize: number;
    upload: CompanyUploadProgress[];
}

const Companies = (state: State = INIT_STATE, action: CompaniesActionType): any => {
    switch (action.type) {
        case CompaniesActionTypes.API_RESPONSE_SUCCESS:
            switch (action.payload.actionType) {
                case CompaniesActionTypes.GET_ALL:
                    return {
                        ...state,
                        messages: {
                            ...state.messages,
                            ...action.payload.data.messages
                        },
                        companies: {
                            ...action.payload.data,
                            data: action.payload.data.data.map((company: object) => Object.entries(company).reduce((memo, [key, value]) => {
                                return {
                                    ...memo,
                                    [key]: value.value
                                }
                            }, {}))
                        },
                        loading: {
                            ...state.loading,
                            getAll: false
                        },
                    };
                case CompaniesActionTypes.GET_ALL_LISTS:
                    return {
                        ...state,
                        messages: {
                            ...state.messages,
                            ...action.payload.data.messages
                        },
                        companyLists: action.payload.data,
                        loading: {
                            ...state.loading,
                            companyLists: false
                        },
                    };
                case CompaniesActionTypes.GET_OPTIONS_LIST:
                    return {
                        ...state,
                        companyListsOptions: action.payload.data,
                        loading: {
                            ...state.loading,
                            companyListsOptions: false
                        },
                    };
                case CompaniesActionTypes.GET_INFO:
                    delete state.stats;
                    return {
                        ...state,
                        stats: action.payload.data.result,
                        messages: {
                            ...state.messages,
                            ...action.payload.data.messages
                        },
                        loading: {
                            ...state.loading,
                            stats: false
                        },
                    };
                case CompaniesActionTypes.UPLOAD:
                    return {
                        ...state,
                        messages: {
                            ...state.messages,
                            success: {...state.messages.success, ...action.payload.data.messages}
                        },
                        loading: {
                            ...state.loading,
                            upload: false
                        },
                    };
                case CompaniesActionTypes.REINDEX:
                    return {
                        ...state,
                        messages: {
                            ...state.messages,
                            success: {...state.messages.success, ...action.payload.data.messages}
                        },
                        loading: {
                            ...state.loading,
                            reindex: false
                        },
                    };
                case CompaniesActionTypes.SAVE_LIST:
                    return {
                        ...state,
                        messages: {
                            ...state.messages,
                            success: {...state.messages.success, ...action.payload.data.messages}
                        },
                        loading: {
                            ...state.loading,
                            saveList: false
                        },
                    };
                case CompaniesActionTypes.ADD_TO_LIST:
                    return {
                        ...state,
                        messages: {
                            ...state.messages,
                            success: {...state.messages.success, ...(action.payload.data.messages ?? {})}
                        },
                        loading: {
                            ...state.loading,
                            addToList: false
                        },
                    };
                case CompaniesActionTypes.GET_VIEW:
                    return {
                        ...state,
                        messages: {
                            ...state.messages,
                            success: {...state.messages.success, ...(action.payload.data.messages ?? {})}
                        },
                        loading: {
                            ...state.loading,
                            getView: false
                        },
                        filtersView: action.payload.data.data
                    };
                case CompaniesActionTypes.GET_CUSTOM_ATTRIBUTES_META:
                    return {
                        ...state,
                        messages: {
                            ...state.messages,
                            success: {...state.messages.success, ...action.payload.data.messages}
                        },
                        loading: {
                            ...state.loading,
                            getCustomAttributesMeta: false
                        },
                        customAttributesMeta: {
                            ...action.payload.data,
                            attributeTypes: action.payload.data.attributeTypes.filter((type: string) => type !== 'select')
                        }
                    };
                case CompaniesActionTypes.SAVE_CUSTOM_ATTRIBUTES_VALUES:
                    return {
                        ...state,
                        messages: {
                            ...state.messages,
                            success: {...state.messages.success, ...action.payload.data.messages}
                        },
                        loading: {
                            ...state.loading,
                            saveCustomAttributesValues: false
                        },
                    };
                case CompaniesActionTypes.SAVE_CUSTOM_ATTRIBUTES:
                    return {
                        ...state,
                        messages: {
                            ...state.messages,
                            success: {...state.messages.success, ...action.payload.data.messages}
                        },
                        loading: {
                            ...state.loading,
                            saveCustomAttributes: false
                        },
                    };
                case CompaniesActionTypes.GET_UPLOAD_PROGRESS:
                    return {
                        ...state,
                        messages: {
                            ...state.messages,
                            success: {...state.messages.success, ...action.payload.data.messages}
                        },
                        loading: {
                            ...state.loading,
                            uploadProgress: false
                        },
                        upload: action.payload.data.data
                    };
                case CompaniesActionTypes.GET_GROUPS_LAYOUT_VIEW:
                    return {
                        ...state,
                        messages: {
                            ...state.messages,
                            success: {...state.messages.success, ...action.payload.data.messages}
                        },
                        loading: {
                            ...state.loading,
                            getGroupsLayoutView: false
                        },
                        groupsLayoutView: action.payload.data
                    };
                case CompaniesActionTypes.SAVE_GROUPS_LAYOUT_VIEW:
                    return {
                        ...state,
                        messages: {
                            ...state.messages,
                            success: {...state.messages.success, ...action.payload.data.messages}
                        },
                        loading: {
                            ...state.loading,
                            saveGroupsLayoutView: false
                        },
                    };
                default:
                    return { ...state };
            }

        case CompaniesActionTypes.API_RESPONSE_ERROR:
            switch (action.payload.actionType) {
                case CompaniesActionTypes.GET_ALL:
                    return {
                        ...state,
                        error: action.payload.error,
                        loading: {
                            ...state.loading,
                            getAll: false
                        },
                    };
                case CompaniesActionTypes.GET_INFO:
                    return {
                        ...state,
                        error: action.payload.error,
                        stats: {},
                        loading: {
                            ...state.loading,
                            stats: false
                        },
                    };
                case CompaniesActionTypes.UPLOAD:
                    return {
                        ...state,
                        error: action.payload.error,
                        messages: {
                            ...state.messages,
                            error: {...state.messages.error, ...action.payload.data.messages}
                        },
                        loading: {
                            ...state.loading,
                            upload: false
                        },
                    };
                case CompaniesActionTypes.REINDEX:
                    return {
                        ...state,
                        error: action.payload.error,
                        messages: {
                            ...state.messages,
                            error: {...state.messages.error, ...action.payload.data.messages}
                        },
                        loading: {
                            ...state.loading,
                            reindex: false
                        },
                    };
                case CompaniesActionTypes.SAVE_LIST:
                    return {
                        ...state,
                        error: action.payload.error,
                        messages: {
                            ...state.messages,
                            error: {...state.messages.error, ...(action.payload.data.messages ?? {})}
                        },
                        loading: {
                            ...state.loading,
                            saveList: false
                        },
                    };
                case CompaniesActionTypes.ADD_TO_LIST:
                    return {
                        ...state,
                        error: action.payload.error,
                        messages: {
                            ...state.messages,
                            error: {...state.messages.error, ...(action.payload.data.messages ?? {})}
                        },
                        loading: {
                            ...state.loading,
                            addToList: false
                        },
                    };
                case CompaniesActionTypes.GET_VIEW:
                    return {
                        ...state,
                        error: action.payload.error,
                        messages: {
                            ...state.messages,
                            error: {...state.messages.error, ...action.payload.data.messages}
                        },
                        loading: {
                            ...state.loading,
                            getView: false
                        }
                    };
                case CompaniesActionTypes.GET_CUSTOM_ATTRIBUTES_META:
                    return {
                        ...state,
                        error: action.payload.error,
                        messages: {
                            ...state.messages,
                            error: {...state.messages.error, ...action.payload.data.messages}
                        },
                        loading: {
                            ...state.loading,
                            getCustomAttributesMeta: false
                        },
                    };
                case CompaniesActionTypes.SAVE_CUSTOM_ATTRIBUTES_VALUES:
                    return {
                        ...state,
                        error: action.payload.error,
                        messages: {
                            ...state.messages,
                            success: {...state.messages.success, ...action.payload.data.messages}
                        },
                        loading: {
                            ...state.loading,
                            saveCustomAttributesValues: false
                        },
                    };
                case CompaniesActionTypes.SAVE_CUSTOM_ATTRIBUTES:
                    return {
                        ...state,
                        error: action.payload.error,
                        messages: {
                            ...state.messages,
                            success: {...state.messages.success, ...action.payload.data.messages}
                        },
                        loading: {
                            ...state.loading,
                            saveCustomAttributes: false
                        },
                    };
                case CompaniesActionTypes.GET_UPLOAD_PROGRESS:
                    return {
                        ...state,
                        error: action.payload.error,
                        messages: {
                            ...state.messages,
                            error: {...state.messages.error, ...action.payload.data.messages}
                        },
                        loading: {
                            ...state.loading,
                            uploadProgress: false
                        },
                    };
                case CompaniesActionTypes.GET_GROUPS_LAYOUT_VIEW:
                    return {
                        ...state,
                        error: action.payload.error,
                        messages: {
                            ...state.messages,
                            error: {...state.messages.error, ...action.payload.data.messages}
                        },
                        loading: {
                            ...state.loading,
                            getGroupsLayoutView: false
                        },
                    };
                case CompaniesActionTypes.SAVE_GROUPS_LAYOUT_VIEW:
                    return {
                        ...state,
                        error: action.payload.error,
                        messages: {
                            ...state.messages,
                            error: {...state.messages.error, ...action.payload.data.messages}
                        },
                        loading: {
                            ...state.loading,
                            saveGroupsLayoutView: false
                        },
                    };
                default:
                    return { ...state };
            }

        case CompaniesActionTypes.GET_ALL:
            return { ...state, loading: {...state.loading, getAll: true} };
        case CompaniesActionTypes.UPLOAD:
            return { ...state, loading: {...state.loading, upload: true} };
        case CompaniesActionTypes.REINDEX:
            return { ...state, loading: {...state.loading, reindex: true} };
        case CompaniesActionTypes.GET_INFO:
            return { ...state, loading: {...state.loading, stats: true} };
        case CompaniesActionTypes.SAVE_CUSTOM_ATTRIBUTES:
            return { ...state, loading: {...state.loading, saveCustomAttributes: true} };
        case CompaniesActionTypes.SET_CUSTOM_ATTRIBUTES_META:
            return { ...state, customAttributesMeta: {...state.customAttributesMeta, customAttributesMeta: action.payload.data} };
        case CompaniesActionTypes.GET_VIEW:
            return { ...state, loading: {...state.loading, getView: true} };
        case CompaniesActionTypes.SAVE_VIEW:
            return { ...state, loading: {...state.loading, saveView: true} };
        case CompaniesActionTypes.SAVE_LIST:
            return { ...state, loading: {...state.loading, saveList: true} };
        case CompaniesActionTypes.ADD_TO_LIST:
            return { ...state, loading: {...state.loading, addToList: true} };
        case CompaniesActionTypes.GET_ALL_LISTS:
            return { ...state, loading: {...state.loading, companyLists: true} };
        case CompaniesActionTypes.SAVE_CUSTOM_ATTRIBUTES_VALUES:
            return { ...state, loading: {...state.loading, saveCustomAttributesValues: true} };
        case CompaniesActionTypes.GET_GROUPS_LAYOUT_VIEW:
            return { ...state, loading: {...state.loading, getGroupsLayoutView: true} };
        case CompaniesActionTypes.GET_UPLOAD_PROGRESS:
            return { ...state, loading: {...state.loading, uploadProgress: true} };
        case CompaniesActionTypes.SAVE_GROUPS_LAYOUT_VIEW:
            return {
                ...state,
                loading: {...state.loading, saveGroupsLayoutView: true},
                messages: {
                    success: {},
                    error: {}
                }
            };
        case CompaniesActionTypes.SET_VIEW:
            return { ...state, filtersView: {...state.filtersView, [action.payload.code]: action.payload.newState} };
        case CompaniesActionTypes.SET_PAGE_SIZE:
            localStorage.setItem("companies.pageSize", action.payload.data as unknown as string);

            return { ...state, pageSize: action.payload.data };
        case CompaniesActionTypes.SET_SORT_BY:
            localStorage.setItem("companies.sortBy", action.payload.data as unknown as string);

            return { ...state, sortBy: action.payload.data };
        case CompaniesActionTypes.SET_SORT_ORDER:
            localStorage.setItem("companies.sortOrder", action.payload.data as unknown as string);

            return { ...state, sortOrder: action.payload.data };
        default:
            return { ...state };
    }
};

export default Companies;
