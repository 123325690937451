
export enum CompaniesActionTypes {
  API_RESPONSE_SUCCESS = "@@companies/API_RESPONSE_SUCCESS",
  API_RESPONSE_ERROR = "@@companies/API_RESPONSE_ERROR",

  GET_ALL = "@@companies/GET_ALL",
  GET_INFO = "@@companies/GET_INFO",

  UPLOAD = "@@companies/UPLOAD",
  REINDEX = "@@companies/REINDEX",
  SAVE_CUSTOM_ATTRIBUTES = "@@companies/SAVE_CUSTOM_ATTRIBUTES",
  SAVE_CUSTOM_ATTRIBUTES_VALUES = "@@companies/SAVE_CUSTOM_ATTRIBUTES_VALUES",
  GET_CUSTOM_ATTRIBUTES_META = "@@companies/GET_CUSTOM_ATTRIBUTES_META",
  SET_CUSTOM_ATTRIBUTES_META = "@@companies/SET_CUSTOM_ATTRIBUTES_META",

  SET_PAGE_SIZE = "@@companies/SET_PAGE_SIZE",
  SET_SORT_BY = "@@companies/SET_SORT_BY",
  SET_SORT_ORDER = "@@companies/SET_SORT_ORDER",

  GET_VIEW = "@@companies/GET_VIEW",
  SET_VIEW = "@@companies/SET_VIEW",
  SAVE_VIEW = "@@companies/SAVE_VIEW",
  SAVE_LIST = "@@companies/SAVE_LIST",
  ADD_TO_LIST = "@@companies/ADD_TO_LIST",

  GET_UPLOAD_PROGRESS = "@@companies/GET_UPLOAD_PROGRESS",

  GET_GROUPS_LAYOUT_VIEW = "@@companies/GET_GROUPS_LAYOUT_VIEW",
  SAVE_GROUPS_LAYOUT_VIEW = "@@companies/SAVE_GROUPS_LAYOUT_VIEW",

  GET_ALL_LISTS = "@@companies_lists/GET_ALL_LISTS",
  GET_OPTIONS_LIST = "@@companies_lists/GET_OPTIONS_LIST"
}

export type ActionTypes = CompaniesActionTypes;

export const PAGE_SIZE = 9;
