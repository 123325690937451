// apicore

// constants
import {SendGridActions} from "./constants";

const INIT_STATE = {
    loading: {},
    messages: {
        success: {},
        error: {},
    }
};

export type ActionTypes = | SendGridActions.API_RESPONSE_SUCCESS
    | SendGridActions.API_RESPONSE_ERROR
    | SendGridActions.VERIFY
    | SendGridActions.VERIFY_BULK
    | SendGridActions.CONNECT;

interface DigitalOceanActionType {
    type: ActionTypes;
    payload: {
        actionType: string;
        data: any;
        error: string;
    };
}

interface State {
    ip?: string;
    reputation?: number;
    token?: string;
    loading: {},
    messages: {
        [key in "success"|"error"]: {}
    },
}

const SendGrid = (state: State = INIT_STATE, action: DigitalOceanActionType): any => {
    switch (action.type) {
        case SendGridActions.API_RESPONSE_SUCCESS:
            switch (action.payload.actionType) {
                //Actions
                case SendGridActions.VERIFY: {
                    return {
                        ...state, verification: action.payload.data.result, loading: {
                            verify: false
                        }, messages: {
                            success: {
                                ...state.messages.success, verify: action.payload.data.message
                            }, error: {
                                ...state.messages.error, verify: ''
                            }
                        }
                    };
                }
                case SendGridActions.VERIFY_BULK: {
                    return {
                        ...state, validateBulk: action.payload.data.result, loading: {
                            validateBulk: false
                        }, messages: {
                            success: {
                                ...state.messages.success, validateBulk: action.payload.data.message
                            }, error: {
                                ...state.messages.error, validateBulk: ''
                            }
                        }
                    };
                }
                case SendGridActions.CONNECT: {
                    return {
                        ...state,
                        token: action.payload.data.token,
                        loading: {
                            connect: false
                        },
                        messages: {
                            success: {
                                ...state.messages.success,
                                connect: action.payload.data.message
                            }, error: {
                                ...state.messages.error,
                                ...action.payload.data.errors
                            }
                        }
                    };
                }
                case SendGridActions.STATUS: {
                    return {
                        ...state,
                        token: action.payload.data.token,
                        loading: {
                            status: false
                        },
                        messages: {
                            success: {
                                ...state.messages.success,
                                status: action.payload.data.message
                            }, error: {
                                ...state.messages.error,
                                ...action.payload.data.errors
                            }
                        }
                    };
                }
                default:
                    return {
                        ...state, loading: {
                            verify: false
                        }
                    };
            }

        case SendGridActions.API_RESPONSE_ERROR:
            switch (action.payload.actionType) {
                case SendGridActions.CONNECT: {
                    return {
                        ...state, messages: {
                            success: {
                                ...state.messages.success, verify: ''
                            },
                            error: {
                                ...state.messages.error , verify: action.payload.error
                            }
                        },
                        loading: {...state.loading, token: false}
                    };
                }
                case SendGridActions.STATUS: {
                    return {
                        ...state, messages: {
                            success: {
                                ...state.messages.success, status: ''
                            },
                            error: {
                                ...state.messages.error , status: action.payload.error
                            }
                        },
                        loading: {...state.loading, token: false}
                    };
                }
                case SendGridActions.VERIFY: {
                    return {
                        ...state, messages: {
                            success: {
                                ...state.messages.success, verify: ''
                            }, error: {
                                ...state.messages.error, verify: action.payload.error
                            }
                        },
                        loading: {...state.loading, verify: false}
                    };
                }
                case SendGridActions.VERIFY_BULK: {
                    return {
                        ...state, messages: {
                            success: {
                                ...state.messages.success, validateBulk: ''
                            }, error: {
                                ...state.messages.error, validateBulk: action.payload.error
                            }
                        },
                        loading: {...state.loading, validateBulk: false}
                    };
                }
                default:
                    return {...state, loading: {}};
            }

        case SendGridActions.VERIFY:
            return {...state, loading: {verify: true}};
        case SendGridActions.VERIFY_BULK:
            return {...state, loading: {validateBulk: true}};
        case SendGridActions.CONNECT:
            return {...state, loading: {token: true}};
        default:
            return {...state, loading: {}};
    }
};

export default SendGrid;
